import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { TableColumn } from '@/type/table';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  gridClasses,
  useGridApiRef,
  GridColumnMenuFilterItem,
  GridColumnMenuSortItem,
  GridColumnMenuPinningItem,
  GridColumnMenuProps,
  GridFilterPanel
} from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Icon from '../Icon';
import './style.css';

const StripedDataGrid = styled(DataGridPro)(() => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#EFEFEF',
    backgroundOpacity: 0.1
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#FCFCFC'
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#EFEFEF',
    fontWeight: 'bold',
    backgroundOpacity: 0.15,
    fontSize: '15px'
  },
  fontSize: '13px',
  backgroundColor: 'white',
  fontFamily: 'Inter',
  letterSpacing: 'wide',
  '& .MuiDataGrid-columnHeaders': {
    borderTop: `1px solid ${'#d3d3d3'}`,
    borderBottom: `1px solid ${'#d3d3d3'}`
  },
  '& .MuiDataGrid-cell': {
    color: '#252023 !important'
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0
  }
}));

type Props = {
  data: Record<string, any>[];
  selectionModel?: any;
  setSelectionModel?: any;
  columns: TableColumn[];
  CustomToolbar?: any;
  CustomMenuIcon?: any;
  pagination?: boolean;
  setGridReference?: any;
  origin: string;
  checkboxSelection?: boolean;
};

function CustomColumnMenu(props: GridColumnMenuProps) {
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu
  };
  return (
    <React.Fragment>
      <Stack px={0.5} py={0.5}>
        <GridColumnMenuSortItem {...itemProps} />
        {/* Only provide filtering on desk */}
        {itemProps.colDef.field === 'desk' ? (
          <GridColumnMenuFilterItem {...itemProps} />
        ) : null}
      </Stack>
      <Divider />
      <Stack px={0.5} py={0.5}>
        <GridColumnMenuPinningItem {...itemProps} />
      </Stack>
      <Divider />
      <Stack px={0.5} py={0.5}>
        <GridColumnMenuFilterItem {...itemProps} />
      </Stack>
    </React.Fragment>
  );
}

const Table: React.FC<Props> = ({
  selectionModel,
  setSelectionModel,
  setGridReference,
  data,
  columns,
  CustomToolbar,
  CustomMenuIcon,
  origin,
  checkboxSelection = false,
}) => {
  const apiRef = useGridApiRef();
  useEffect(() => {
    if (setGridReference) {
      setGridReference(apiRef);
    }
  }, [apiRef]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const colFieldMap = columns.map((column) => column.field);
  const [orderedColumns, setOrderedColumns] = useState(colFieldMap);
  const cols = useMemo(() => {
    const tempCols: any = {};

    columns.map((column) => {
      // fill the cols array with info from the columns data
      const newObject = {
        field: column.field,
        headerName: column.label,
        minWidth: 60,
        align: 'left',
        flex: 1,
        valueOptions: column.valueOptions ? column.valueOptions : undefined,
        headerAlign: 'left',
        renderCell: column.render ? column.render : column.renderCell
      };

      tempCols[column.field] = newObject;
    });

    return orderedColumns.reduce((acc: any, field: string) => {
      return [...acc, tempCols[field]];
    }, []);
  }, [orderedColumns]);

  return (
    <Box sx={{ height: origin === 'history' ? '50vh' : '80vh', width: '100%' }}>
      <StripedDataGrid
        getRowId={(row) => {
          return origin === 'employees'
            ? row.employeeId
            : origin === 'players'
            ? row.id
            // Previous value below: row.filename
            : `${row.fileName}-${row.audit_Id}`;
        }}
        localeText={{ toolbarColumns: '' }}
        rows={data}
        rowCount={data.length}
        pagination={true}
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={origin === "history" ? [5] : [10, 50, 100]}
        onPageChange={(newPage: any) => setPage(newPage)}
        onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
        columns={cols}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        disableRowSelectionOnClick
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        experimentalFeatures={{ newEditingApi: true }}
        disableColumnFilter={false}
        apiRef={apiRef}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectionModel(newRowSelectionModel);
        }}
        selectionModel={selectionModel}
        isCellEditable={() => false}
        slots={{
          toolbar: () => CustomToolbar(selectionModel),
          columnMenuIcon: CustomMenuIcon,
          columnMenu: CustomColumnMenu,
          columnMenuFilterIcon: () => <Icon name="filter" />,
          columnMenuPinLeftIcon: () => <Icon name="left-pin" />,
          columnMenuPinRightIcon: () => <Icon name="right-pin" />,
          columnMenuSortAscendingIcon: () => <Icon name="sort-ascending" />,
          columnMenuSortDescendingIcon: () => <Icon name="sort-descending" />,
          filterPanelRemoveAllIcon: () => <Icon name="delete" />,
          filterPanelAddIcon: () => <Icon name="add" />
        }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              // Customize inputs by passing props
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small'
                }
              }
            },
            sx: {
              // Customize inputs using css selectors
              '& .MuiDataGrid-filterForm': { px: 2, pt: 1.8 },
              '& .MuiDataGrid-filterFormLogicOperatorInput': {
                mr: 2,
                backgroundColor: '#EFF0F6'
              },
              '& .MuiDataGrid-filterFormColumnInput': {
                mr: 2,
                width: 150,
                backgroundColor: '#EFF0F6'
              },
              '& .MuiDataGrid-filterFormOperatorInput': {
                mr: 2,
                backgroundColor: '#EFF0F6'
              },
              '& .MuiDataGrid-filterFormValueInput': {
                width: 200,
                backgroundColor: '#EFF0F6'
              }
            }
          }
        }}
        sx={{
          boxShadow: 2,
          border: 1,
          borderRadius: '0.5rem',
          borderColor: '#d3d3d3',
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: '550 !important'
          }
        }}
      />
    </Box>
  );
};

export default Table;

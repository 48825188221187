import Button from '@/components/common/Button';
import Modal, { ModalProps } from '@/components/common/Modal';
import Loader from '../../loaders/Loader';
import { useAppDispatch } from '@/store';
import { login } from '@/store/auth';

type Props = Partial<ModalProps> & {
  emailUsedToLogin: string;
  passwordUsedToLogin: string;
};

const LoginEmailSentModal: React.FC<Props> = ({
  open = true,
  emailUsedToLogin,
  passwordUsedToLogin,
  onClose = () => null
}) => {
  const dispatch = useAppDispatch();
  return (
    <Modal open={open} smallScreen onClose={() => null}>
      <div className="flex px-12 pb-4 flex-col gap-5">
        <h1 className=" font-bold text-[32px] leading-10">Verify your email</h1>
        <div className=" flex p-8 flex-col justify-center items-center self-stretch gap-4 bg-[#EFEFEF]">
          <div className="flex flex-col justify-center items-center gap-4 text-center">
            <Loader />
            <h1 className=" text-lg">Waiting for verification...</h1>
            <p className=" tracking-[0.75px] text-base font-[650] ">
              Please check your corporate email for a verification and click on
              the link received.
            </p>
          </div>
          <Button
            variant="secondary"
            className="w-full"
            onClick={async () => {
              await dispatch(
                login({
                  email: emailUsedToLogin,
                  password: passwordUsedToLogin
                })
              );
            }}>
            Resend an email
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginEmailSentModal;

import { PLAYER_STATUS_COLOR, VERIFIED_STATUS_COLOR } from '../ColorMaps';

export const VerificationStatus = (value: any) => {
  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ backgroundColor: VERIFIED_STATUS_COLOR[value] }}
      className=" px-3 py-0.5 gap-1 justify-center align-middle rounded-lg w-fit">
      {value}
    </div>
  );
};

export const PlayerStatus = (value: any) => {
  return (
    <div className={`flex items-center`}>
      <span 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{ backgroundColor: PLAYER_STATUS_COLOR[value] }}
        className={`w-4 h-4 rounded-full mr-1`}
      />
      {value}
    </div>
  );
};

import React, { useState, useMemo, ChangeEvent } from 'react';
import Popper from '@/components/common/Popper';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import clsx from 'clsx';
import './style.css';
import ToggleInput from '@/components/common/ToggleInput';

export type FilterItem = {
  label: string;
  value: string;
  avatar?: any;
};

type Props = {
  label: string;
  onChangeFilter: (value: string) => void;
  selectedValue: string;
  searchable?: boolean;
  placeholder?: string;
  options: FilterItem[];
  multi?: boolean;
  isDisabled?: boolean;
  pickFirstValueWhenEmpty?: boolean;
};

const DropdownFilter: React.FC<Props> = ({
  label,
  onChangeFilter,
  selectedValue,
  options,
  searchable,
  placeholder = '',
  multi,
  isDisabled,
  pickFirstValueWhenEmpty
}) => {
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangeOption = (value: string) => (status: boolean) => {
    onChangeFilter(value);
    setExpanded(false);
  };

  const filteredOption = useMemo(() => {
    return options.filter(
      (option) => option.label.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [options, search]);

  return (
    <Popper
      className="w-screen editEmployee-input"
      placement="bottom-start"
      disabled={isDisabled}
      onChangeOpening={setExpanded}
      isOpen={expanded}
      anchor={
        <div
          className={
            'flex items-center justify-between py-4 w-full text-sm font-semibold'
          }>
          <ToggleInput
            editable={!isDisabled}
            label={label}
            value={selectedValue}
            className="w-full text-sm placeholder:text-secondary focus:outline-0 relative mt-2 bg-inherit"
          />
          <Icon className={clsx(expanded && 'rotate-180')} name="caret-down" />
        </div>
      }>
      <div
        className={clsx(
          'rounded-xl px-3 shadow-dropdown mt-2',
          searchable ? 'pt-[22px] pb-3 w-[338px]' : 'w-[183px] py-4'
        )}>
        <div
          className={clsx(
            'rounded px-2',
            searchable && 'border border-primary px-5'
          )}>
          {searchable && (
            <input
              className="text-sm placeholder:text-secondary h-10 border-b border-b-grey w-full focus:outline-0"
              placeholder={placeholder}
              value={search}
              onChange={handleSearch}
            />
          )}
          <div className="py-2">
            <div className="max-h-[244px] overflow-y-auto">
              {filteredOption.map((item, index) => (
                <div
                  key={index}
                  className={clsx('flex items-center py-2', {
                    'pt-0': index === 0,
                    'pb-0': index === filteredOption.length - 1
                  })}>
                  <Checkbox
                    checked={selectedValue === item.value}
                    onChange={handleChangeOption(item.value)}
                    className="shrink-0"
                  />
                  {item.avatar && (
                    <span className="avatar-wrapper">
                      <img
                        className="w-full h-full"
                        src={item.avatar}
                        alt="avatar"
                      />
                    </span>
                  )}
                  <span
                    className={clsx(
                      'text-sm break-word pl-3',
                      selectedValue === item.value && 'font-semibold'
                    )}>
                    {item.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Popper>
  );
};

export default DropdownFilter;

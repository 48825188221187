import React from 'react';
import { SwitchUnstyled, SwitchUnstyledProps } from '@mui/base';
import './style.css';

type Props = SwitchUnstyledProps & {
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
};

const Switch: React.FC<Props> = ({
  className,
  isChecked,
  isDisabled,
  onChange,
  ...rest
}) => {
  return (
    <div className={`${className ? className : ''}`}>
      <SwitchUnstyled
        disabled={isDisabled}
        onChange={onChange}
        className="custom-switch"
        checked={isChecked}
        {...rest}
      />
    </div>
  );
};

export default Switch;

import React, { ChangeEvent } from 'react';
import MuiSwitch from '@/components/common/Switch';
import { EmployeeDetails as EmployeeDetailsType } from '@/type/employee';
import EditEmployeeDropdownFilter from '@/components/widgets/filters/EditEmployeeDropdownFilter';

type Props = {
  onChangeValue?: (value: string) => void;
  initialEmployeeName: string;
  avatar?: string;
  data: EmployeeDetailsType;
  setData: any;
  id: any;
  name: string;
  status: string;
  title: string;
  fromEmployee: boolean;
  departmentOptions: any;
  employeeTitleOptions: any;
  employeeContractTypeOptions: any;
  employeeRoleOptions: any;
  isEditScreen: boolean;
};

const DropdownInformation: React.FC<Props> = ({
  onChangeValue,
  initialEmployeeName,
  departmentOptions,
  employeeTitleOptions,
  employeeContractTypeOptions,
  employeeRoleOptions,
  id,
  data,
  setData,
  name,
  isEditScreen
}) => {
  const editableContactFields: Array<{
    field: keyof EmployeeDetailsType;
    label: string;
  }> = [
    {
      field: 'employeeRole',
      label: 'Permission Type'
    },
    {
      field: 'isRemote',
      label: 'Remote'
    },
    {
      field: 'department',
      label: 'Department (Required)'
    },
    {
      field: 'employeeTitle',
      label: 'Title (Required)'
    },
    {
      field: 'employeeContractType',
      label: 'Employee Type (Required)'
    }
  ];

  const getDropdownOptions = (field: string) => {
    switch (field) {
      case 'employeeRole':
        return employeeRoleOptions;
      case 'department':
        return departmentOptions;
      case 'employeeContractType':
        return employeeContractTypeOptions;
      case 'employeeTitle':
        return employeeTitleOptions;
      default:
        return [];
    }
  };

  const getSelectedDropdownValue = (label: string) => {
    switch (label) {
      case 'employeeRole':
        return data.employeeRole;
      case 'department':
        return data.department;
      case 'employeeContractType':
        return data.employeeContractType;
      case 'employeeTitle':
        return data.employeeTitle;
      default:
        return '';
    }
  };

  return (
    // If this is the personal toolbar from an employee's profile.
    <>
      <div className="grid grid-cols-2 gap-2">
        {editableContactFields.map((item, index) => (
          <div key={index}>
            {item.field === 'isRemote' ? (
              <div className="flex justify-between">
                <div className="label">Remote</div>
                <MuiSwitch
                  isDisabled={!isEditScreen}
                  className={`${
                    isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                  }`}
                  onClick={() => {
                    setData({
                      ...data,
                      isRemote: data.isRemote == 'true' ? 'false' : 'true'
                    });
                  }}
                  isChecked={data.isRemote != 'true' ? false : true}
                />
              </div>
            ) : (
              <div className="form-row-input">
                <EditEmployeeDropdownFilter
                  options={getDropdownOptions(item.field)}
                  label={item.label}
                  isDisabled={!isEditScreen || item.field === 'employeeRole'}
                  selectedValue={getSelectedDropdownValue(item.field)}
                  searchable={false}
                  onChangeFilter={(newVal) => {
                    setData({ ...data, [item.field]: newVal });
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default DropdownInformation;

import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ReactCountryFlag from 'react-country-flag';
import lookup from 'country-code-lookup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  label?: string;
  value: string | number | number[] | boolean | undefined;
  regex?: RegExp;
  isWrongFormat?: boolean;
  country?: string | undefined;
  playerId?: string | number;
  placeholder?: string;
  editable?: boolean;
  isMultiline?: boolean;
  onChangeValue?: (value: string) => void;
  className?: string;
  hasLimit?: boolean;
};

const ToggleInput: React.FC<Props> = ({
  label,
  country,
  playerId,
  value,
  regex,
  isWrongFormat,
  editable,
  placeholder,
  isMultiline,
  onChangeValue,
  className,
  hasLimit
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeValue?.(e.target.value);
  };

  const maxLength = 100;

  return (
    <div
      className={clsx(
        className,
        'flex mb-2 gap-1.5 bg-slate-200 bg-opacity-10',
        editable ? 'flex-col' : ''
      )}>
      {editable ? (
        <>
          <TextField
            id="filled-input"
            label={label}
            value={value !== undefined ? value : ''}
            multiline={isMultiline}
            rows={isMultiline ? 4 : 1}
            placeholder={placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              !isTouched && setIsTouched(true);
              return handleChangeInput(e);
            }}
            className={`w-full rounded-xl truncate text-left ${
              isWrongFormat && isTouched ? 'bg-errorscale-light' : 'bg-[#EFEFEF]'
            }`}
            sx={{
              '& label': {
                left: '0.7rem',
                top: '0.3rem'
              },
              '& label.Mui-focused': {
                color: '#636365'
              },
              '& .Mui-readOnly.MuiInputBase-readOnly': {
                color: '#AEADAD'
              },
              '& .MuiInputBase-multiline': {
                left: '0.2rem',
                top: '0.3rem'
              }
            }}
            inputProps={{ maxLength: maxLength }} 
            InputProps={{
              disableUnderline: true,
              className: !label ? 'p-3' : 'p-1',
              startAdornment: (
                <InputAdornment position="start">
                  {country && lookup?.byCountry(country)?.iso2 && (
                    <ReactCountryFlag
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      countryCode={lookup.byCountry(country).iso2}
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                        borderRadius: '50%',
                        marginLeft: '0.7rem'
                      }}
                      svg
                    />
                  )}
                </InputAdornment>
              )
            }}
            variant="standard"
          />
          {hasLimit && typeof value === 'string' && (
            <div
              className={`self-stretch text-right text-xs font-medium font-['Inter'] leading-none tracking-tight ${
                value?.length === maxLength ? 'text-red-500' : 'text-zinc-500'
              }`}
            >
              {value?.length}/{maxLength}
            </div>
          )}
        </>
      ) : (
        <TextField
          label={label}
          value={value !== undefined ? value : ''}
          multiline={isMultiline}
          rows={isMultiline ? 5 : 1}
          className="w-full rounded-xl truncate text-left bg-[#F7F7F7] !text-[#AEADAD]"
          sx={{
            '& label': {
              left: '0.7rem',
              top: '0.3rem'
            },
            '& label.Mui-focused': {
              color: '#636365'
            },
            '& .Mui-readOnly.MuiInputBase-readOnly': {
              color: '#AEADAD'
            },
            '& .MuiInputBase-multiline': {
              left: '0.2rem',
              top: '0.3rem'
            }
          }}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: 'p-1',
            startAdornment: (
              <InputAdornment position="start">
                {country && lookup?.byCountry(country)?.iso2 && (
                  <ReactCountryFlag
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    countryCode={lookup.byCountry(country).iso2}
                    style={{
                      width: '1.25rem',
                      height: '1.25rem',
                      borderRadius: '50%',
                      marginLeft: '0.7rem'
                    }}
                    svg
                  />
                )}
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      )}
    </div>
  );
};

export default ToggleInput;

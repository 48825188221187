import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import { menu } from '@/constants/menu';
import { useAppDispatch } from '@/store';
import { logout } from '@/store/auth';
import clsx from 'clsx';
import './style.css';

type Props = {
  collapsed: boolean;
  isTablet: boolean;
  onCollapse: () => void;
};

const Sidebar: React.FC<Props> = ({ collapsed, isTablet, onCollapse }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleLink = () => {
    if (isTablet && !collapsed) onCollapse();
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const user = useMemo(() => {
    const account = localStorage.getItem('dd_account');
    if (account) return JSON.parse(account);
    return null;
  }, []);

  return (
    <div
      className={clsx(
        'fixed h-[100vh] z-50',
        isTablet && !collapsed && 'collapsed-bg'
      )}>
      <div className={clsx('sidebar', collapsed ? 'collapsed' : '')}>
        <div className="pb-8 w-full flex items-center px-3">
          <>
            <Link to="/">
              <img
                src={`${
                  collapsed ? '/logo-mark.svg' : '/logo-mark-open-sidebar.svg'
                }`}
                alt="logo"
              />
            </Link>
          </>
        </div>

        <div
          className={clsx(
            'flex-1 pt-2 overflow-hidden w-full items-center',
            collapsed && 'flex flex-col'
          )}>
          {menu.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              onClick={handleLink}
              className={clsx(
                'flex w-full mb-5 hover:text-ctascale-lightMain',
                {
                  'border-r-ctascale-lightMain border-r-4':
                    item.path === location.pathname
                },
                collapsed ? 'justify-center' : 'pl-3'
              )}>
              <Icon
                className={clsx('flex-shrink-0', {
                  'text-ctascale-lightMain font-medium':
                    item.path === location.pathname
                })}
                name={item.icon}
              />
              <p className="ml-3.5 text-black">{item.label}</p>
            </Link>
          ))}
        </div>
        <div
          className={clsx(
            'flex items-center overflow-hidden opacity-70',
            !collapsed && 'pl-3'
          )}>
          <div className="p-2.5 bg-ctascale-darkMain text-white rounded-lg">
            {user.firstName[0]}
            {user.lastName[0]}
          </div>
          <p className="ml-2">
            {user.firstName} {user.lastName}
          </p>
        </div>

        <div
          className={clsx(
            'mt-4 flex items-center text-ctascale-lightMain cursor-pointer hover:text-errorscale-dark overflow-hidden',
            !collapsed && 'pl-3'
          )}
          onClick={handleLogout}>
          <Icon name="logout" className="flex-shrink-0" />
          <span
            className={clsx(
              'font-medium ml-3 whitespace-nowrap',
              collapsed && 'hidden'
            )}>
            Log out
          </span>
        </div>

        <IconButton
          className="bg-purple collapse-button bottom-[100px]"
          onClick={() => onCollapse()}
          small={false}>
          <Icon name="caret-down" className="rotate-90" />
        </IconButton>
      </div>
    </div>
  );
};

export default Sidebar;

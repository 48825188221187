import EmployeeTable from '@/components/widgets/tables/EmployeeTable';
import FilterEmployee from '@/components/widgets/filters/FilterEmployee';
import HeaderSection from '@/components/widgets/sections/HeaderSection';

const Employees = () => {
  return (
    <>
      <HeaderSection pageName="Employees" origin="employees" />

      <div className="mx-6">
        <FilterEmployee />

        <EmployeeTable />
      </div>
    </>
  );
};

export default Employees;

import { useEffect, useState } from 'react';

export function useResponsive() {
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const resizeScreen = () => {
      if (window.innerWidth > 1024) {
        setIsTablet(false);
      } else if (window.innerWidth <= 1024) {
        setIsTablet(true);
      } else if (window.innerWidth <= 765) {
        setIsMobile(true);
      } else if (window.innerWidth > 765) {
        setIsMobile(false);
      } else {
        setIsMobile(false);
        setIsTablet(false);
      }
    };

    resizeScreen();

    window.addEventListener('resize', resizeScreen);

    return () => {
      window.removeEventListener('resize', resizeScreen);
    };
  }, []);

  return {
    isTablet,
    isMobile
  };
}

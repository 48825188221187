export const saveFilterOptions = (
  filterOption: any,
  type: 'transaction' | 'player' | 'employee' | 'home'
) => {
  window.localStorage.setItem(`${type}_filter`, JSON.stringify(filterOption));
};

export const getFilterOptions = (
  type: 'transaction' | 'player' | 'employee' | 'home'
) => {
  const payload = window.localStorage.getItem(`${type}_filter`);
  if (payload) return JSON.parse(payload);
  return null;
};

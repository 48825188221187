import React, { useState, forwardRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Divider, Box } from '@mui/material';
import Checkbox from '../../../common/Checkbox';
import { styled } from '@mui/material/styles';
import {
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps
} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import Icon from '@/components/common/Icon';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

type Props = {
  date: any;
  setDate: any;
  readOnly?: boolean;
  label?: string;
};

const DateRangePickerDayColor =
  window.location.href.startsWith('https://dashboard-dev.sendcrypto.com') ||
  window.location.href.startsWith('https://dashboard.sendcrypto.com')
    ? '#610BEF !important'
    : '#D5A23D !important';

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> &
    React.RefAttributes<HTMLInputElement>
) => JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = forwardRef(function myInput(
  props: SingleInputDateRangeFieldProps<Dayjs>,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <SingleInputDateRangeField
      {...props}
      ref={ref}
      hiddenLabel={true}
      onKeyDown={(e) => {
        // Since the library did not support disabling backspace, this section turns everything into an Enter keypress
        e.code = 'Enter';
        e.keyCode = 13;
        e.key = 'Enter';
        e.which = 13;
        e.preventDefault();
        e.stopPropagation();
      }}
      sx={{
        '& .MuiInputBase-root': {
          width: '95%',
          cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
          textAlign: 'center',
          cursor: 'pointer'
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name="calendar" className='ml-[1.5rem] text-black'/>
          </InputAdornment>
        ),
        disableUnderline: true
      }}
    />
  );
}) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({
    theme,
    isHighlighting,
    isStartOfHighlighting,
    isEndOfHighlighting,
    outsideCurrentMonth,
    selected
  }) => ({
    ...(!outsideCurrentMonth &&
      isHighlighting && {
        borderRadius: 0,
        backgroundColor: '#EBECFE !important',
        color: theme.palette.common.white,
        ...(isStartOfHighlighting && {
          borderTopLeftRadius: '50% !important',
          backgroundColor: DateRangePickerDayColor,
          borderBottomLeftRadius: '50% !important'
        }),
        ...(isEndOfHighlighting && {
          borderTopRightRadius: '50% !important',
          backgroundColor: DateRangePickerDayColor,
          borderBottomRightRadius: '50% !important'
        }),
        ...{
          '&:hover, &:focus': {
            backgroundColor: DateRangePickerDayColor
          },
          '[class*="Mui-selected"]': {
            backgroundColor: DateRangePickerDayColor
          }
        }
      })
  })
);

const CustomDateRangePicker: React.FC<Props> = ({
  setDate,
  date,
  readOnly,
  label
}) => {
  const [quickAction, setQuickAction] = useState(null);
  const today = dayjs();
  const DEFAULT_DATE: any = [today.subtract(31, 'day'), dayjs(today)];

  const items: any = [
    {
      label: 'Today',
      getValue: () => {
        return [today, today];
      }
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        return [today.subtract(6, 'day'), today];
      }
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        return [today.subtract(29, 'day'), today];
      }
    },
    {
      label: 'Last Year',
      getValue: () => {
        return [today.subtract(365, 'day'), today];
      }
    }
  ];

  function CustomRangeShortcuts(props: any) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item: any) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        getValue: item.getValue,
        disabled: !isValid(newValue)
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          width: '100%'
        }}>
        <h1 className="p-4 text-[#6E7191]">Quick Actions</h1>
        <List
          dense
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',

            px: theme.spacing(2),
            '& .MuiListItem-root': {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1)
            }
          })}>
          {resolvedItems.map((item: any) => {
            return (
              <ListItem key={item.label}>
                <Checkbox
                  checked={quickAction === item.label}
                  onChange={() => {
                    setQuickAction(item.label);
                    item.onClick();
                  }}
                  label={item.label}
                />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['SingleInputDateRangeField']}>
        <DateRangePicker
          onChange={(newValue) => {
            setDate({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              startDate: dayjs(newValue[0]).format('YYYY-MM-DD'),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              endDate: dayjs(newValue[1]).format('YYYY-MM-DD')
            });
          }}
          closeOnSelect={false}
          sx={{
            cursor: 'pointer'
          }}
          slots={{
            day: DateRangePickerDay,
            field: WrappedSingleInputDateRangeField,
            shortcuts: CustomRangeShortcuts
          }}
          slotProps={{
            shortcuts: {
              items: items
            },
            textField: {
              variant: 'standard'
            }
          }}
          format={'DD MMM YYYY'}
          disabled={readOnly}
          value={[dayjs(date.startDate), dayjs(date.endDate)]}
          calendars={2}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;

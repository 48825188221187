import service from '@/service/http.service';
import {
  GetEmployeeRequest,
  EmployeeFilterDataResponse,
  GetEmployeeResponse,
  GetEmployeeInfoRequest,
  PostEmployeeRequest,
  PostEmployeeResponse,
  PutEmployeeRequest,
  PutEmployeeResponse
} from '@/type/api';
import { UpdatePermission, UpdateEmployeeRequest } from '@/type/employee';
import { EmployeeInformation, EmployeeWallet } from '@/type/employee';
import { AxiosError } from 'axios';

export const getEmployeeFilterData =
  (): Promise<EmployeeFilterDataResponse> => {
    return service
      .get('/employees/filters')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.status;
      });
  };

export const getEmployees = (
  params: GetEmployeeRequest
): Promise<GetEmployeeResponse> => {
  return service
    .get('/employees', {}, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.status;
    });
};

export const postEmployees = (
  body: PostEmployeeRequest
): Promise<PostEmployeeResponse> => {
  return service
    .post('/employees', body)
    .then((response) => {
      if (response) {
        return response;
      } else {
        throw new Error('Email is duplicated or not allowed');
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const putEmployees = (
  body: UpdateEmployeeRequest
): Promise<PutEmployeeResponse> => {
  return service
    .put(`/employees/${body.employeeId}`, body)
    .then((response) => response)
    .catch((error) => {
      return error;
    });
};

export const getEmployeeWallets = (
  params: GetEmployeeInfoRequest
): Promise<EmployeeWallet> => {
  return service
    .get('/employees/employee-wallets', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getEmployeeInfo = (id: string): Promise<any> => {
  return service
    .get(`/employees/${id}`, {})
    .then((response) => response?.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const isResetPasswordTokenValid = (token: string) => {
  return service
    .get(`/employees/verify-reset-password?token=${token}`, {})
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

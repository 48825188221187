import React from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Button from '@/components/common/Button';

type Props = ModalProps & {
  message: string;
  onCancel: any;
  onConfirm: any;
  fromCancel: boolean;
};

const CancelOrConfirmModal: React.FC<Props> = ({
  message,
  onCancel,
  fromCancel,
  onConfirm,
  open,
  onClose
}) => {
  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <h2 className="text-title font-bold mb-5 mx-8 text-left">{message}</h2>
      <div className="flex justify-center gap-5">
        <Button variant="secondary" onClick={() => onClose()}>
          Keep Editing
        </Button>
        <Button
          onClick={() => {
            fromCancel ? onCancel() : onConfirm();
            onClose();
          }}
          variant="bg-beautiful">
          {fromCancel ? 'Yes, discard.' : 'Yes, save.'}
        </Button>
      </div>
    </Modal>
  );
};

export default CancelOrConfirmModal;

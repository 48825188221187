import CloseIcon from '@/components/common/CloseButton';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import Button from '@/components/common/Button';
import IconButton from '@/components/common/IconButton';
import Icon from '@/components/common/Icon';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { saveAs } from 'file-saver';
import React from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

interface PdfModalProps extends ModalProps{
  pdfUrl: string | null;
}

const PdfViewerModal: React.FC<PdfModalProps> = ({ open, onClose, pdfUrl }) => {
  const toolbarPluginInstance = toolbarPlugin({
    searchPlugin: {
        keyword: 'PDF',
    },
    selectionModePlugin: {
        selectionMode: SelectionMode.Hand,
    },
});
const { Toolbar } = toolbarPluginInstance;

  return (
    pdfUrl != null ? (
  <Modal open={open} onClose={onClose} smallScreen={true}>
    <div>
    <div className="flex justify-end mb-2 items-center gap-3">
      {/* {localStorage.getItem('isAdmin') === 'true' && ( */}
        <Button onClick={() => saveAs(pdfUrl, `Report.pdf`)}
              variant="bg-beautiful">
              Download
        </Button>
      {/* )} */}
      <IconButton
            onClick={() => onClose()}
            className="bg-black h-[48px] w-[48px] text-white flex justify-center">
            <Icon name="close" />
      </IconButton>
    </div>
      <Toolbar/>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <div
          style={{
            height: 'fit-content',
            width: '100%',
          }}
        >
          <Viewer  fileUrl={pdfUrl} plugins={[toolbarPluginInstance]}/>
        </div>
      </Worker>
    </div>
    </Modal>
) : null);
};

export default PdfViewerModal;
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';

const selectSelf = (state: RootState) => state.employee;

export const employeesSelector = createSelector(selectSelf, (state) => state.employees);

export const transactionsSelector = createSelector(
  selectSelf,
  (state) => state.transactions
);

export const employeeFilterDataSelector = createSelector(
  selectSelf,
  (state) => state.filter
);

export const transactionFilterDataSelector = createSelector(
  selectSelf,
  (state) => state.transactionFilter
);

export const employeeInfoSelector = createSelector(
  selectSelf,
  (state) => state.employee
);

import React from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';

const ResetSuccessModal: React.FC<ModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <div className="px-12 py-4 w-fit flex justify-center content-center items-center">
        <div className='justify-center items-center'>
          <h2 className=" text-[32px] mb-5 font-bold">
            Password successfully sent ✅
          </h2>
          <p className="text-base font-semibold text-[#716B70] w-4/5">
            New password has been sent successfully by email. You can check your
            email and use it.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ResetSuccessModal;

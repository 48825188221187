import { createSlice } from '@reduxjs/toolkit';
import {
  getEmployees,
  getEmployeeFilterData,
  getEmployeeInfo
} from './actions';
import { EmployeeState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GetEmployeesResponse, GetEmployeeResponse } from '@/type/api';

const initialState: EmployeeState = {
  filter: null,
  transactionFilter: null,
  employees: null,
  transactions: null,
  employee: null
};

const setEmployeeFilterData = (
  state: EmployeeState,
  action: PayloadAction<any>
) => {
  state.filter = action.payload;
};

const setEmployee = (
  state: EmployeeState,
  action: PayloadAction<GetEmployeeResponse>
) => {
  state.employee = action.payload.employee;
};

const setEmployees = (
  state: EmployeeState,
  action: PayloadAction<GetEmployeesResponse>
) => {
  state.employees = action.payload ? action.payload : [];
};

export const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees,
    setEmployeeFilterData
  },
  extraReducers(builder) {
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      setEmployees(state, action);
    });
    builder.addCase(getEmployeeFilterData.fulfilled, (state, action) => {
      setEmployeeFilterData(state, action);
    });
    builder.addCase(getEmployeeInfo.fulfilled, (state, action) => {
      setEmployee(state, action);
    });
  }
});

import React from 'react';
import clsx from 'clsx';
import './style.css';

type CheckboxProps = {
  checked?: boolean;
  onChange?: (status: boolean) => void;
  className?: string;
  label?: string;
  icon?: 'check' | 'minus';
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange = () => null,
  className,
  label,
  icon = 'check'
}) => {
  const handleChange = (event: any) => {
    onChange(event.target.checked);
  };

  return (
    <label className={clsx('checkbox', className)}>
      {label && <span className="text-sm pl-6 whitespace-nowrap">{label}</span>}
      <input
        className="checkbox-input"
        type="checkbox"
        onChange={handleChange}
        checked={checked}
      />
      <span
        className={clsx('mark', icon === 'check' ? 'checkIcon' : 'minusIcon')}
      />
    </label>
  );
};

export default Checkbox;

import React from 'react';
import IconButton from '../IconButton';

type ColorKeys = 'black' | 'red';  // Add more keys as needed

interface CloseButtonProps {
    onClick: () => void;  // assuming onClick is a function that takes no arguments and returns nothing
    color?: ColorKeys;  // Optional color prop
  }

const inputColors: { [key: string]: string } = {
  'black': '#262338',
  'red': '#9E0038',
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, color = 'black' }) => {

  const strokeColor = inputColors[color] || '#262338';

  return (
    <IconButton onClick={onClick}>
        <svg className={color} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Line Lightmode/Close X">
                <path id="Vector" d="M8 8.00003L25.0323 25.0323M8 25.0323L25.0323 8" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    </IconButton>
  );
};

export default CloseButton;

import React, { useMemo, useState } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import ToggleInput from '@/components/common/ToggleInput';
import Button from '@/components/common/Button';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import * as authApi from '@/service/auth.service';
import { EMAIL_REGEX } from '@/components/common/Regex';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX)
    .required('Email is required')
    .trim('Required')
});

type Props = ModalProps & {
  onSuccess?: () => void;
};

const ForgotPasswordModal: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const [error, setError] = useState('');

  const profileInfo = useMemo(() => {
    const info = window.localStorage.getItem('dd_account');
    if (info) return JSON.parse(info);
    return {};
  }, []);

  const initialValue = {
    email: profileInfo?.email
  };

  const handleSubmit = async (
    values: { email: string },
    { setSubmitting }: FormikHelpers<{ email: string }>
  ) => {
    setSubmitting(true);
    await authApi
      .sendResetPasswordEmail(values)
      .then(() => {
        onSuccess?.();
        onClose();
      })
      .catch((error) => {
        // TODO: please add error message
        console.log('[Error] sending reset password email', error);
        setError('Email does not exist.');
      });
    setSubmitting(false);
  };

  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <div className="max-w-[583px]">
        <h2 className="text-title mb-5 font-semibold">Forgotten password</h2>
        <p className="text-sm leading-6 max-w-[414px] mb-5 text-[#A0A3BD]">
          Enter the email you use to login in the system, so we can send you a
          link for password reset.
        </p>

        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}>
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <Form autoComplete="off">
                <div className="mb-5">
                  <ToggleInput
                    label="Email"
                    placeholder='example@10bet.com'
                    editable={true}
                    value={values.email}
                    onChangeValue={(newVal) => {
                      setFieldValue('email', newVal);
                      setError('');
                    }}
                  />
                  {values.email !== undefined &&
                    !EMAIL_REGEX.test(values.email) && (
                      <p className="block pt-0 text-xs pb-2 text-red-500">
                        Please fix this email format
                      </p>
                    )}
                  {error !== '' && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      {error}
                    </p>
                  )}
                </div>

                <div className="flex justify-end self-end gap-5">
                  <Button
                    className="w-[100px]"
                    onClick={onClose}
                    variant="secondary">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      !isValid || isSubmitting || error !== '' || errors?.email
                        ? true
                        : false
                    }
                    className="w-[100px]"
                    variant="bg-beautiful">
                    Send
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;

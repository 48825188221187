import React, { useState, useRef, useCallback, DragEvent, useEffect, memo } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import CloseIcon from '@/components/common/CloseButton';
import Tooltip from '@mui/material/Tooltip';
// import { postUploadDocumentFile  } from '@/service/player.service';
import { PlayerDocument } from '@/type/player';

type Props = ModalProps & {
  // message: string;
  // onCancel: any;
  // onConfirm: any;
  // fromCancel: boolean;
  // padding:string
  // playerId: number;
  // isEdit: boolean,
  // rowData?: PlayerDocument,
  // setIsEdit: (value: boolean) => void;
  setIsCSVUploaded: (value: boolean) => void;
  onCSVDataExtracted: (data: string[]) => void; 
};

const UploadCSVModal: React.FC<Props> = ({
  // message,
  // onCancel,
  // fromCancel,
  // onConfirm,
  // playerId,
  // isEdit,
  // rowData,
  // setIsEdit,
  open,
  onClose,
  setIsCSVUploaded,
  onCSVDataExtracted
}) => {
  // Check if exists, and if format is correct
  const [isFileAccepted, setIsFileAccepted] = useState(false);
  const [isTooBig, setIsTooBig] = useState(false);
  const [isWrongFormat, setIsWrongFormat] = useState(false);

  // Add drag and drop state tracking, accepted file types and max file size
  const [dragIsOver, setDragIsOver] = useState(false);
  const [file, setFile] = useState<File | null>(null); // Single file state

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 20MB in bytes

  const MAX_ID_AMOUNT = 100 // Max amount of rows accepted

  const acceptedFileTypes = [
    "csv", 
    // "xls", 
    // "xlsx"
  ];


  const resetStates = () => {
    setIsTooBig(false);
    setIsWrongFormat(false);
    setIsFileAccepted(false);
    setFile(null);
  };
  
  // Control if valid file was uploaded
  useEffect(() => {
    setIsFileAccepted(!!file && !isTooBig && !isWrongFormat);
  }, [file, isTooBig, isWrongFormat]);

  // Process file before declaring closing the modal
  const handleFileProcessing = () => {
    if (isFileAccepted && !isTooBig && !isWrongFormat) {
      setIsCSVUploaded(true);
      // Close the modal explicitly after successful processing
      onClose();
    }
  };

  // Add function to properly render file syze and type
  function formatFileSize(bytes: any): string {
    // Convert bytes to kilobytes
    const kilobytes = bytes / 1024;

    // If less than 1 KB, return "1 KB"
    if (kilobytes < 1) {
      return "1 KB";
    }

    // If less than 1024 KB (or 1 MB), return in KB
    if (kilobytes < 1024) {
        return `${Math.round(kilobytes)} KB`;
    }

    // Otherwise, convert to megabytes and return
    const megabytes = kilobytes / 1024;
    return `${Math.round(megabytes)} MB`;
  }


  const acceptedFileTypesString = acceptedFileTypes.join(', ').toUpperCase();

  // Handle closing model if it's in Edit mode and change to Uplad by default
  const handleClose = () => {
    resetStates();
  
    if (onClose) {
      onClose();
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragIsOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragIsOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    // Reset states before processing new file
    resetStates();

    event.preventDefault();
    setDragIsOver(false);

    // Fetch the files
    const droppedFile = event.dataTransfer.files[0]; // Only consider the first file

    if (droppedFile) {
      setFile(droppedFile);

      if (droppedFile.size > MAX_FILE_SIZE) {
        setIsTooBig(true);
        return; // Exit the function if the file is too big
      }

      if (!acceptedFileTypes.includes(droppedFile.name.split('.').pop() || "")) {
        setIsWrongFormat(true);
        console.log("File type not accepted!");
        return; // Exit the function if the file format is not accepted
      }

      // Extract values from CSV file
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const values = text.split(/\r\n|\n/); // Split by line breaks

        // Check if file has more than 100 rows
        if (values.length > MAX_ID_AMOUNT) {
          setIsTooBig(true);
          console.log("File has too many rows!");
          return; // Exit the function if the file has too many rows
        }
  
        // Validation: Ensure only one column and no header
        if (values.some(value => value.includes(','))) {
          console.log('CSV format is wrong, setting wrong format flag');
          setIsWrongFormat(true); // Set wrong format flag
          setIsFileAccepted(false);
          // Do not close the modal here, let the user see the error message
        } else {
          console.log('CSV format is correct, updating parent state');
          // If the format is correct, then update the parent state
          onCSVDataExtracted(values.filter(value => value.trim() !== '')); // Filter out empty lines
          setIsFileAccepted(true); // Set file accepted flag
          handleFileProcessing();
          onClose();
        }

      };
      reader.readAsText(droppedFile);

    }

  };

  function handleCancelFile() {
    console.log("Click cancel file upload");
    resetStates();
  }


  const WrongFormat = ({ file }: { file: File | null }) => {
    return (
      <div className='h-72 p-6 bg-zinc-100 rounded-lg shadow flex flex-col justify-start items-center gap-8 inline-flex'>
        <div className="self-stretch flex flex-col justify-center items-center gap-4 flex">
            <div className="w-12 h-12 relative">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Line Lightmode/Circle Actions Close">
                  <path id="Vector" d="M18 18L30 30M18 30L30 18M46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24Z" stroke="#9E0038" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
              </svg>
            </div>
            <div className="self-stretch h-14 flex flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch text-center text-gray-800 text-lg font-normal font-['Inter'] leading-loose tracking-wide">
                  {isWrongFormat && 'CSV file or format not valid.' }
                  {isTooBig && 'File is too Big! Try again' }
                  </div>
                <div className="self-stretch text-center text-neutral-500 text-xs font-medium font-['Inter'] leading-none tracking-tight">
                  { isWrongFormat &&
                    <span className='underline decoration-1 underline-offset-2 decoration-[bg-beautiful]'>
                      <Tooltip title={acceptedFileTypesString}>
                        <span>
                          See accepted formats
                        </span>
                      </Tooltip>
                    </span>
                  }
                  { isTooBig && 'Max file size is 10MB, or 100 rows'}
                </div>
                <div className="mt-4 w-full self-stretch h-[72px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch p-4 bg-fuchsia-100 rounded-lg border-2 border-rose-800 justify-between items-center inline-flex">
                        <div className="justify-start items-start gap-4 flex">
                            <div className="w-10 h-10 bg-stone-800 rounded-lg justify-center items-center flex">
                                <div className="w-[9.73px] h-[9.73px] relative flex-col justify-start items-start flex">
                                  <img src="/uploadedFile.png" alt="Uploaded file icon" className="w-full h-full object-cover" />
                                </div>
                            </div>
                            <div className="flex-col justify-start items-start inline-flex">
                                <div className="text-stone-800 text-base font-normal font-['Inter'] leading-normal tracking-wide">
                                  {file?.name}
                                  </div>
                                <div className="text-neutral-500 text-xs font-medium font-['Inter'] leading-none tracking-tight">{formatFileSize(file?.size)}</div>
                            </div>
                        </div>
                        <div className="w-6 h-6 relative self-center">
                          <CloseIcon onClick={handleCancelFile} color='red'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }

  const AcceptFiles = () => {
    return (
      <div
        className={`h-48 p-6 bg-zinc-100 rounded-lg shadow flex flex-col justify-start items-center gap-8 inline-flex ${dragIsOver ? 'bg-lightgray' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        >
        <div className="flex-col justify-start items-center gap-6 flex">
          <div className="w-12 h-12 relative">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Line Lightmode/Upload">
              <path id="Vector" d="M29.9642 8.95636L28.233 9.95782C28.5905 10.5758 29.2502 10.9564 29.9642 10.9564V8.95636ZM3.10263 24.6325C3.45193 25.6803 4.58457 26.2467 5.63246 25.8974C6.68034 25.5481 7.24666 24.4154 6.89737 23.3675L3.10263 24.6325ZM40.2111 23.1056C39.7172 24.0935 40.1176 25.2949 41.1056 25.7889C42.0935 26.2828 43.2949 25.8824 43.7889 24.8944L40.2111 23.1056ZM29.781 30.7799C30.578 31.5446 31.8441 31.5185 32.6088 30.7215C33.3736 29.9246 33.3475 28.6585 32.5505 27.8937L29.781 30.7799ZM22.9991 21.5002L24.3839 20.0571C23.61 19.3146 22.3882 19.3146 21.6144 20.0571L22.9991 21.5002ZM13.4477 27.8937C12.6507 28.6585 12.6246 29.9246 13.3894 30.7215C14.1541 31.5185 15.4202 31.5446 16.2172 30.7799L13.4477 27.8937ZM24.999 23.5614C24.999 22.4568 24.1036 21.5614 22.999 21.5614C21.8945 21.5614 20.999 22.4568 20.999 23.5614H24.999ZM20.999 45.5614C20.999 46.666 21.8945 47.5614 22.999 47.5614C24.1036 47.5614 24.999 46.666 24.999 45.5614H20.999ZM17.9127 4C22.3196 4 26.1702 6.39188 28.233 9.95782L31.6954 7.95491C28.947 3.20381 23.8052 0 17.9127 0V4ZM6 15.9127C6 9.99346 11.3998 4 17.9127 4V0C9.05808 0 2 7.91927 2 15.9127H6ZM6.89737 23.3675C6.01265 20.7134 6 18.0183 6 15.9127H2C2 17.9817 1.98735 21.2866 3.10263 24.6325L6.89737 23.3675ZM41 18C41 20.0504 40.9708 21.5863 40.2111 23.1056L43.7889 24.8944C45.0292 22.4137 45 19.9496 45 18H41ZM31.8255 10.9564C34.2575 10.9564 36.6146 11.8159 38.3349 13.1733C40.0575 14.5325 41 16.2607 41 18H45C45 14.7393 43.2366 11.9457 40.8127 10.0331C38.3864 8.11865 35.1563 6.95636 31.8255 6.95636V10.9564ZM29.9642 10.9564H31.8255V6.95636H29.9642V10.9564ZM32.5505 27.8937L24.3839 20.0571L21.6144 22.9433L29.781 30.7799L32.5505 27.8937ZM21.6144 20.0571L13.4477 27.8937L16.2172 30.7799L24.3839 22.9433L21.6144 20.0571ZM20.999 23.5614V45.5614H24.999V23.5614H20.999Z" fill="#76FD80"/>
              </g>
            </svg>
          </div>
          <div className="flex-col justify-start items-start gap-2 flex">
              <div className="w-[413px] text-center text-stone-800 text-lg font-normal font-['Inter'] leading-loose tracking-wide">Drag & Drop your CSV file</div>
          </div>
          <div className="self-stretch text-center text-neutral-500 text-xs font-medium font-['Inter'] leading-none tracking-tight">
            <span className='underline decoration-1 underline-offset-2 decoration-[bg-beautiful]'>
              <Tooltip title={acceptedFileTypesString}>
                <span>
                  See accepted formats
                </span>
              </Tooltip>
            </span>
            , max file size: 20MB
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal open={open} onClose={handleClose} smallScreen={true} padding='0px'>
      <div className='px-4 py-2'>
        <div className='flex justify-end mb-4'>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className='flex flex-col pb-12 pl-12 pr-12'>
          <h2 className="text-[32px] font-['Inter'] font-bold mb-5 text-left">
            Upload CSV file
          </h2>
          {(isTooBig || isWrongFormat) ? <WrongFormat file={file} /> : <AcceptFiles />}
        </div>

      </div>
    </Modal>
  );
};

export default UploadCSVModal;

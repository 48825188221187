import React from 'react';
import { ReactComponent as Add } from '@/assets/images/icons/add_icon.svg';
import { ReactComponent as AddCircle } from '@/assets/images/icons/add_circle_icon.svg';
import { ReactComponent as AddFile } from '@/assets/images/icons/add_file_icon.svg';
import { ReactComponent as SortAscending } from '@/assets/images/icons/sort_asc_icon.svg';
import { ReactComponent as SortDescending } from '@/assets/images/icons/sort_desc_icon.svg';
import { ReactComponent as Filter } from '@/assets/images/icons/filter_icon.svg';
import { ReactComponent as RightPin} from '@/assets/images/icons/right_pin_icon.svg';
import { ReactComponent as LeftPin} from '@/assets/images/icons/left_pin_icon copy.svg';
import { ReactComponent as AlertCircle } from '@/assets/images/icons/alert_circle_icon.svg';
import { ReactComponent as ArrowBack } from '@/assets/images/icons/arrow_backward_icon.svg';
import { ReactComponent as ArrowDecrease } from '@/assets/images/icons/arrow_decrease_icon.svg';
import { ReactComponent as ArrowForward } from '@/assets/images/icons/arrow_forward_icon.svg';
import { ReactComponent as ArrowIncrease } from '@/assets/images/icons/arrow_increase_icon.svg';
import { ReactComponent as BackOffice } from '@/assets/images/icons/backoffice_icon.svg';
import { ReactComponent as Back } from '@/assets/images/icons/back_icon.svg';
import { ReactComponent as Calendar } from '@/assets/images/icons/calendar.svg';
import { ReactComponent as CaretDown } from '@/assets/images/icons/caret_down_icon.svg';
import { ReactComponent as Close } from '@/assets/images/icons/close_icon.svg';
import { ReactComponent as Copy } from '@/assets/images/icons/copy_icon.svg';
import { ReactComponent as Delete } from '@/assets/images/icons/delete_icon.svg';
import { ReactComponent as Download } from '@/assets/images/icons/download_icon.svg';
import { ReactComponent as Drag } from '@/assets/images/icons/drag_icon.svg';
import { ReactComponent as Employee } from '@/assets/images/icons/employee_icon.svg';
import { ReactComponent as ClosedEye } from '@/assets/images/icons/eye_closed_icon.svg';
import { ReactComponent as Eye } from '@/assets/images/icons/eye_icon.svg';
import { ReactComponent as EmployeeGroup } from '@/assets/images/icons/employees_menu_icon.svg';
import { ReactComponent as Trophy } from '@/assets/images/icons/players_menu_icon.svg';
import { ReactComponent as Columns } from '../../../assets/images/icons/columns.svg';
import { ReactComponent as LineChart } from '@/assets/images/icons/line_chart_icon.svg';
import { ReactComponent as Logout } from '@/assets/images/icons/logout_icon.svg';
import { ReactComponent as MoreVert } from '@/assets/images/icons/more_vert_icon.svg';
import { ReactComponent as Pencil } from '@/assets/images/icons/pencil_icon.svg';
import { ReactComponent as Triangle } from '@/assets/images/icons/triangle_icon.svg';
import { ReactComponent as Save } from '@/assets/images/icons/save_icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/images/icons/search_icon.svg';
import { ReactComponent as Wallet } from '@/assets/images/icons/wallet_icon.svg';

const icons = {
  add: Add,
  'add-circle': AddCircle,
  'add-file': AddFile,
  'alert-circle': AlertCircle,
  'arrow-back': ArrowBack,
  'arrow-decrease': ArrowDecrease,
  'arrow-forward': ArrowForward,
  'arrow-increase': ArrowIncrease,
  'sort-ascending': SortAscending,
  'sort-descending': SortDescending,
  'right-pin': RightPin,
  'left-pin': LeftPin,
  'back-office': BackOffice,
  back: Back,
  calendar: Calendar,
  'caret-down': CaretDown,
  close: Close,
  copy: Copy,
  columns: Columns,
  delete: Delete,
  download: Download,
  drag: Drag,
  employee: Employee,
  'closed-eye': ClosedEye,
  eye: Eye,
  filter: Filter,
  'players-menu-icon': Trophy,
  'employees-menu-icon': EmployeeGroup,
  'line-chart': LineChart,
  logout: Logout,
  'more-vert': MoreVert,
  pencil: Pencil,
  triangle: Triangle,
  search: SearchIcon,
  save: Save,
  wallet: Wallet
};

export type IconType =
  | 'add'
  | 'add-circle'
  | 'add-file'
  | 'alert-circle'
  | 'arrow-back'
  | 'arrow-decrease'
  | 'arrow-forward'
  | 'arrow-increase'
  | 'left-pin'
  | 'right-pin'
  | 'sort-ascending'
  | 'sort-descending'
  | 'back-office'
  | 'back'
  | 'closed-eye'
  | 'calendar'
  | 'caret-down'
  | 'close'
  | 'copy'
  | 'columns'
  | 'players-menu-icon'
  | 'employees-menu-icon'
  | 'delete'
  | 'download'
  | 'drag'
  | 'eye'
  | 'filter'
  | 'employee'
  | 'line-chart'
  | 'logout'
  | 'more-vert'
  | 'triangle'
  | 'pencil'
  | 'search'
  | 'save'
  | 'wallet';

type Props = {
  name: IconType;
  className?: string;
};

const Icon: React.FC<Props> = ({ name, className }) => {
  const AppIcon = icons[name];

  if (!AppIcon) {
    return null;
  }

  return <AppIcon className={className} />;
};

export default Icon;

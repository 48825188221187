import React, { useState, useEffect, useMemo } from 'react';
import Icon from '@/components/common/Icon';
import PersonalTable from '@/components/widgets/tables/PersonalTable';
import PersonalToolbar from '@/components/widgets/sections/PersonalInformation/PersonalToolbar';
import PersonalDetails from '@/components/widgets/sections/PersonalInformation/PersonalDetails';
import { commonDateFormat } from '@/utils/date';
import Status from '@/components/common/Status';
import { PlayerInformation } from '@/type/player';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';

// currently hard code, will change later
const documentAttachedPhotos =
  'https://criterio-sevilla/?si=51bab2a3a7c741f892db81a81013cb3c&utm_so';

type Props = {
  data: PlayerInformation;
  setProfileInfo: any;
  initialPlayerName: string;
  playerId: string;
};

const PersonalInformation: React.FC<Props> = ({
  setProfileInfo,
  data,
  initialPlayerName,
  playerId
}) => {
  const params = useParams();

  const handleChangeValue = (field: string) => (value: string) => {
    setProfileInfo((prev: any) => ({
      ...prev,
      [field]: value
    }));
  };

  const personalData = useMemo(() => {
    return [
      {
        type: 'Registration date',
        information: commonDateFormat(data?.reg_Date)
      },
      {
        type: 'Registered IP',
        information: data?.registrationIP,
      },
      {
        type: 'Registration source',
        information: data?.registrationSource
      },
      {
        type: 'Reg product',
        information: commonDateFormat(data?.regProductName)
      },
      {
        type: 'FD date',
        information: data?.fD_Date
      },
      {
        type: 'PFA date',
        information: data?.pfA_Date
      }
    ];
  }, [data]);

  return (
    <div className="flex gap-4 w-full">
      <div className="grid col-span-4 grid-cols-4 gap-4 shrink grow">
        <h2 className="text-title font-medium col-span-4">
          Personal information
        </h2>
        <div className="shadow-paper bg-white p-4 sm:py-5 sm:px-8 col-span-1 grid-flow-auto rounded-3xl">
          <PersonalToolbar
            id={params.id}
            loginName={data?.userName}
            name={data?.customerName}
            initialPlayerName={initialPlayerName}
            playerStatus={data?.statusName}
            verificationStatus={data?.verificationStatus}
            title="Test Player"
            onChangeValue={handleChangeValue('customerName')}
            fromEmployee={false}
          />
        </div>
        <div className="shadow-paper bg-white p-4 sm:py-5 sm:px-8 col-span-3 grid-flow-auto rounded-3xl">
          <PersonalDetails data={data} id={params.id} />
        </div>
      </div>
      <div className="!min-w-[380px]">
        <h2 className="text-title font-medium mb-4">Technical data</h2>
        { data && <PersonalTable data={personalData} country={data?.countryName} />}
      </div>
    </div>
  );
};

export default PersonalInformation;

import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import MuiSwitch from '@/components/common/Switch';
import EditEmployeeDropdownFilter from '@/components/widgets/filters/EditEmployeeDropdownFilter';
import { postEmployees } from '@/service/employee.service';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getEmployeeFilterData,
  employeeFilterDataSelector
} from '@/store/employee';
import {
  ALPHA_DASH_REGEX,
  NUMERIC_DASH_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
  DATE_REGEX
} from '@/components/common/Regex';
import {
  isDateOfBirthProblematic,
  isContinueDisabled
} from '@/containers/Employees/helperFunctions';
import ToggleInput from '@/components/common/ToggleInput';
import HeaderSection from '@/components/widgets/sections/HeaderSection';
import { Store } from 'react-notifications-component';
import './style.css';

const AddNewEmployee = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [country, setCountry] = useState<string[]>([]);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [permissionType, setPermissionType] = useState('Regular Employee');
  const [department, setDepartment] = useState<string[]>([]);
  const [title, setTitle] = useState<string[]>([]);
  const [contractType, setContractType] = useState<string[]>([]);
  const [isRemote, setIsRemote] = useState<string>('false');
  const filterData = useAppSelector(employeeFilterDataSelector);

  // Comment to publish branch
  useEffect(() => {
    const fetchFilterData = async () => {
      await dispatch(getEmployeeFilterData());
    };
    fetchFilterData();
  }, []);

  const employeeTitleOptions = useMemo(() => {
    if (filterData?.titles) {
      const options = filterData.titles.map((item) => ({
        label: item,
        value: item
      }));
      setTitle([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const contractTypeOptions = useMemo(() => {
    if (filterData?.contractTypes) {
      const options = filterData.contractTypes.map((item) => ({
        label: item,
        value: item
      }));

      setContractType([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const countryOptions = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.countries) {
      const options = filterData.countries.map((item) => ({
        label: item.countryName,
        value: item.countryName
      }));

      setCountry([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const departmentOptions = useMemo(() => {
    if (filterData?.departments) {
      // Since we are not expecting an initial All ____ from the backend
      const fixedFilterData = [...filterData.departments];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      setDepartment([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const handleCreateEmployee = async () => {

    const permissions = [
      {
          "id": 1,
          "name": "Delete Player Profile",
          "active": false
      },
      {
          "id": 2,
          "name": "Edit Document Information",
          "active": false
      },
      {
          "id": 3,
          "name": "Upload/Delete Documents",
          "active": false
      }
    ];

    try {
      const newEmployeeObject = {
        firstName: name,
        lastName: surname,
        countryCode: country[0],
        phoneNumber: phone,
        email: email,
        birthDate: birthdate,
        city: city,
        address: address,
        permissionType: permissionType,
        department: department[0],
        employeeTitle: title[0],
        employeeContractType: contractType[0],
        isRemote: isRemote == 'true' ? 'true' : 'false',
        permissions: permissions
      };

      const response = await postEmployees(newEmployeeObject);

      console.log("API Response:", response.data);
      Store.addNotification({
        title: 'Employee has been successfully added! ✅',
        message: '',
        type: 'success',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      navigate(-1);
    } catch (err: any) {
      console.log(err);
      // const errorMessage = err.message ? err.message : "An error occurred while creating the employee.";
      
      Store.addNotification({
        title: `Employee creation failed!: ${err} ❌`,
        message: '',
        type: 'danger',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  return (
    <>
      <HeaderSection pageName="Add new employee" origin="addNewEmployee" />

      <div className="mx-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-8 ">
          <div className="mb-10">
            <p className="text-title font-medium mb-4">Personal Information</p>
            <div className="shadow-paper bg-white p-4 py-5 rounded-3xl h-full">
              <div className="grid grid-cols-2 mb-5 gap-x-2">
                <div className="col-span-1">
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setName(newVal)}
                    value={name}
                    regex={ALPHA_DASH_REGEX}
                    isWrongFormat={!ALPHA_DASH_REGEX.test(name)}
                    label={'Name (Required)'}
                    placeholder="Jane"
                  />
                  {/* {name.length < 2 && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      Name must be at least 2 characters
                    </p>
                  )} */}
                </div>
                <div className="col-span-1">
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setSurname(newVal)}
                    value={surname}
                    regex={ALPHA_DASH_REGEX}
                    isWrongFormat={!ALPHA_DASH_REGEX.test(surname)}
                    label={'Surname (Required)'}
                    placeholder="Cooper"
                  />
                  {/* {surname.length < 2 && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      Surname must be at least 2 characters
                    </p>
                  )} */}
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setPhone(newVal)}
                    value={phone}
                    regex={PHONE_REGEX}
                    isWrongFormat={!PHONE_REGEX.test(phone)}
                    label={'Phone number (Required)'}
                    placeholder="359xxxxxxxxx"
                  />
                  {/* {!PHONE_REGEX.test(phone) && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      Please fix this phone number format
                    </p>
                  )} */}
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setEmail(newVal)}
                    value={email}
                    regex={EMAIL_REGEX}
                    label={'Email (Required)'}
                    isWrongFormat={!EMAIL_REGEX.test(email)}
                    placeholder="janecooper@gmail.com"
                  />
                  {email !== '' && !EMAIL_REGEX.test(email) && (
                    <p className="block pt-0 text-xs pb-2 text-errorscale-dark">
                      Please fix this email format
                    </p>
                  )}
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => {
                      newVal.length <= 10 &&
                        setBirthdate(
                          (birthdate.length === 1 || birthdate.length === 4) &&
                            birthdate.length < newVal.length
                            ? newVal + '-'
                            : newVal
                        );
                    }}
                    value={birthdate}
                    label={'Birth date'}
                    isWrongFormat={isDateOfBirthProblematic(birthdate)}
                    placeholder="DD-MM-YYYY"
                  />
                  {isDateOfBirthProblematic(birthdate) && (
                    <p className="block pt-0 text-xs pb-2 text-errorscale-dark">
                      Please fix this date format
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-2">
                <div className="form-row-input">
                  <EditEmployeeDropdownFilter
                    options={countryOptions}
                    label="Country"
                    selectedValue={country[0]}
                    searchable={true}
                    placeholder="Search for country"
                    onChangeFilter={(e) => {
                      setCountry([e]);
                    }}
                  />
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setCity(newVal)}
                    value={city}
                    label={'City'}
                    placeholder="City"
                  />
                </div>
                <div>
                  <div className="label"></div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setAddress(newVal)}
                    value={address}
                    label={'Address'}
                    placeholder="Address"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10">
            <p className="text-title font-medium mb-4">10bet Information</p>
            <div className="shadow-paper bg-white p-4 py-5 rounded-3xl h-full">
              <div className="grid grid-cols-2 gap-2 mb-5">
                <div className="flex">
                  <EditEmployeeDropdownFilter
                    options={[
                      { label: 'Regular Employee', value: 'Regular Employee' },
                      { label: 'Admin', value: 'Admin' }
                    ]}
                    label="Permission Type"
                    isDisabled={true}
                    selectedValue={permissionType}
                    onChangeFilter={(e) => {
                      setPermissionType(e);
                    }}
                  />
                </div>
                <div className="flex justify-between">
                  <div className="label">Remote</div>
                  <div className="flex items-center">
                    <MuiSwitch
                      className="isEditScreen"
                      isChecked={isRemote != 'true' ? false : true}
                      onClick={() =>
                        setIsRemote(isRemote == 'true' ? 'false' : 'true')
                      }
                    />
                    <span className="text-sm text-grey ml-2 mb-1">
                      {isRemote == 'true' ? 'Yes' : 'No'}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <EditEmployeeDropdownFilter
                    options={departmentOptions}
                    label="Department (Required)"
                    selectedValue={department[0]}
                    onChangeFilter={(e) => {
                      setDepartment([e]);
                    }}
                  />
                </div>
                <div className="flex">
                  <EditEmployeeDropdownFilter
                    options={employeeTitleOptions}
                    label="Title (Required)"
                    selectedValue={title[0]}
                    onChangeFilter={(e) => {
                      setTitle([e]);
                    }}
                  />
                </div>
                <div className="flex mb-5">
                  <EditEmployeeDropdownFilter
                    options={contractTypeOptions}
                    label="Contract Type (Required)"
                    selectedValue={contractType[0]}
                    onChangeFilter={(e) => {
                      setContractType([e]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col float-right">
          <button
            onClick={() => handleCreateEmployee()}
            className={`disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed min-w-[260px] my-10 rounded-3xl font-semibold h-[50px] px-5 flex items-center justify-center hover:shadow-button text-white bg-beautiful disabled:bg-grey active:shadow-activeButton active:bg-primary`}
            disabled={isContinueDisabled(
              name,
              surname,
              phone,
              email,
              birthdate
            )}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewEmployee;

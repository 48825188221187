import React, { useState, useMemo, useEffect } from 'react';
import DropdownFilter from '@/components/widgets/filters/DropdownFilter';
import InputFilter from '@/components/widgets/filters/InputFilter';
import FilterContainer from '@/components/widgets/filters/FilterContainer';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getEmployees,
  employeeFilterDataSelector,
  getEmployeeFilterData
} from '@/store/employee';
import { optimizeObject } from '@/utils/object';
import { getFilterOptions, saveFilterOptions } from '@/utils/filter';
import Button from '@/components/common/Button';
import SearchBarFilter from '@/components/widgets/filters/SearchBarFilter';

const FilterEmployee = () => {
  const dispatch = useAppDispatch();

  const savedOption = getFilterOptions('employee');

  const [searchBar, setSearchBar] = useState<string>(
    () => savedOption?.searchBar ?? ''
  );

  const filterData = useAppSelector(employeeFilterDataSelector);
  const [status, setStatus] = useState<string[]>([]);
  const [country, setCountry] = useState<string[]>([]);
  const [department, setDepartment] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setLoading(true);
        await dispatch(getEmployeeFilterData());
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  const onClear = () => {
    setSearchBar('');
    setStatus(['All Statuses']);
    setCountry(['All Countries']);
    setDepartment(['All Departments']);
  };

  const onApply = () => {
    const filter = {
      searchBar: searchBar.trim(),
      department:
        department[0] === 'All Departments' ? '' : department.join(','),
      countrycode: country[0] === 'All Countries' ? '' : country.join(','),
      status: status[0] === 'All Statuses' ? '' : status[0]
    };

    saveFilterOptions(filter, 'employee');

    const { ...rest } = filter;

    dispatch(getEmployees(optimizeObject(rest)));
  };

  useEffect(() => {
    if (filterData) {
      onApply();
    }
  }, [filterData]);

  const countryOptions = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.countries) {
      const options = filterData.countries.map((item) => ({
        label: item.countryName,
        value: item.countryName
      }));

      options.unshift({ label: 'All Countries', value: 'All Countries' });
      setCountry([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const departments = useMemo(() => {
    if (filterData?.departments) {
      // Since we are not expecting an initial All ____ from the backend
      const fixedFilterData = [...filterData.departments];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      options.unshift({ label: 'All Departments', value: 'All Departments' });

      // const savedOption = getFilterOptions('employee');

      // if (savedOption?.departments) {
      //   setDepartment([savedOption.departments]);
      // } else {
      //   setDepartment([options[0].value]);
      // }

      setDepartment([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const statuses = useMemo(() => {
    const options = [
      { label: 'All Statuses', value: 'All Statuses' },
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '2' }
    ];

    const savedOption = getFilterOptions('employee');
    if (savedOption?.status) {
      setStatus([savedOption.status]);
    } else {
      setStatus([options[0].value]);
    }
    return options;
  }, [filterData]);

  return (
    <div className="shadow-paper bg-white rounded-xl px-6 py-3 mb-4">
      <div className="flex gap-4 flex-nowrap">
        <div className="w-full min-w-[100px] grow">
          <SearchBarFilter
            searchBar={searchBar}
            setSearchBar={setSearchBar}
            origin="employees"
          />
        </div>
        <div>
          <FilterContainer>
            <DropdownFilter
              options={statuses}
              label="Status"
              placeholder="Search for statuses"
              selectedValue={status}
              onChangeFilter={setStatus}
              searchable
              pickFirstValueWhenEmpty
            />
            <DropdownFilter
              options={countryOptions}
              label="Country"
              placeholder="Search for country"
              selectedValue={country}
              onChangeFilter={setCountry}
              searchable
              multi
              pickFirstValueWhenEmpty
            />
            <DropdownFilter
              options={departments}
              label="Department"
              placeholder="Search for departments"
              selectedValue={department}
              onChangeFilter={setDepartment}
              searchable
              multi
              pickFirstValueWhenEmpty
            />
          </FilterContainer>
        </div>
      </div>
      <div className="flex justify-end mt-3 gap-2">
        <Button variant="secondary" onClick={() => onClear()}>
          Clear filter
        </Button>
        <Button variant="bg-beautiful" onClick={() => onApply()}>
          Apply filter
        </Button>
      </div>
    </div>
  );
};

export default FilterEmployee;

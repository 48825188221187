import React from 'react';
import { ErrorMessage as FormikErrorMessage } from 'formik';

type Props = {
  name: string;
};

const ErrorMessage: React.FC<Props> = ({ name }) => {
  return (
    <FormikErrorMessage name={name}>
      {(message) => (
        <p className="font-semibold text-error text-xs mt-1">{message}</p>
      )}
    </FormikErrorMessage>
  );
};

export default ErrorMessage;

import React, { useEffect, useState } from 'react';
import MuiSwitch from '@/components/common/Switch';
import { UpdatePermission } from '@/type/employee';

type Props = {
  // data: EmployeeDetailsType;
  // setData: any;
  // id: string | undefined;
  // countryOptions: any;
  isEditScreen: boolean;
  employeePermissions: UpdatePermission[];
  setEmployeePermissions: React.Dispatch<React.SetStateAction<UpdatePermission[]>>;
};

const EmployeePermissions: React.FC<Props> = ({
  // data,
  // id,
  // setData,
  // countryOptions,
  isEditScreen,
  employeePermissions,
  setEmployeePermissions
}) => {

  const findPermissionActiveStatus = (permissionName: string): boolean => {
    const permission = employeePermissions.find(p => p.name === permissionName);
    return permission?.active || false;
  };

  const togglePermissionStatus = (permissionName: string) => {
    const updatedPermissions = employeePermissions.map(permission => {
      if (permission.name === permissionName) {
        return { ...permission, active: !permission.active };
      }
      return permission;
    });
    setEmployeePermissions(updatedPermissions);
  };

  return (
    <div className="shadow-paper bg-white px-4 rounded-lg flex flex-col p-6 gap-4">
      <div
        className={
          'flex content-center self-center justify-between w-full'
        }>
        <div>
          <p className="text-neutral-900 text-base font-semibold font-['Inter'] leading-normal tracking-wide">Upload / Delete document</p>
          <p className="text-gray-300 text-sm font-medium font-['Inter'] leading-none tracking-tight">
            Employee can upload or delete documents
          </p>
        </div>
        <div>
          <MuiSwitch
            isDisabled={!isEditScreen}
            className={`${
              isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
            }`}
            onClick={() => togglePermissionStatus("Upload/Delete Documents")}
            isChecked={findPermissionActiveStatus("Upload/Delete Documents")}
          />
        </div>
      </div>
      <div
        className={
          'flex content-center self-center justify-between w-full'
        }>
        <div>
          <p className="text-neutral-900 text-base font-semibold font-['Inter'] leading-normal tracking-wide">Edit information</p>
          <p className="text-gray-300 text-sm font-medium font-['Inter'] leading-none tracking-tight">
            Employee can edit information
          </p>
        </div>
        <div>
          <MuiSwitch
            isDisabled={!isEditScreen}
            className={`${
              isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
            }`}
            onClick={() => togglePermissionStatus("Edit Document Information")}
            isChecked={findPermissionActiveStatus("Edit Document Information")}
          />
        </div>
      </div>
      <div
        className={
          'flex content-center self-center justify-between w-full'
        }>
        <div>
          <p className="text-neutral-900 text-base font-semibold font-['Inter'] leading-normal tracking-wide">Delete player profile</p>
          <p className="text-gray-300 text-sm font-medium font-['Inter'] leading-none tracking-tight">
            Employee can delete player profiles
          </p>
        </div>
        <div>
          <MuiSwitch
            isDisabled={!isEditScreen}
            className={`${
              isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
            }`}
            onClick={() => togglePermissionStatus("Delete Player Profile")}
            isChecked={findPermissionActiveStatus("Delete Player Profile")}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeePermissions;

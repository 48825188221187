import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetEmployeeRequest,
  GetEmployeeResponse,
  EmployeeFilterDataResponse
} from '@/type/api';
import * as employeeApi from '@/service/employee.service';
import { AxiosError } from 'axios';

export const getEmployees = createAsyncThunk(
  'employee/getEmployees',
  async (
    data: GetEmployeeRequest,
    { rejectWithValue }
  ): Promise<GetEmployeeResponse | any> => {
    return employeeApi
      .getEmployees(data)
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError) => {
        rejectWithValue(error.message);
      });
  }
);

export const getEmployeeFilterData = createAsyncThunk(
  'employee/getFilterData',
  async (
    data: undefined,
    { rejectWithValue }
  ): Promise<EmployeeFilterDataResponse | any> => {
    return employeeApi
      .getEmployeeFilterData()
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error.message);
      });
  }
);

export const getEmployeeInfo = createAsyncThunk(
  'employee/getemployeebyid',
  async (
    id: string,
    { rejectWithValue }
  ): Promise<GetEmployeeResponse | any> => {
    return employeeApi
      .getEmployeeInfo(id)
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError) => {
        rejectWithValue(error.message);
      });
  }
);

import React from 'react';
import { MoonLoader } from 'react-spinners';
import clsx from 'clsx';
import { useContext } from 'react';

type Props = {
  className?: string;
};

const Loader: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={clsx(
        'h-full w-full flex justify-center items-center p-5',
        className
      )}>
      <MoonLoader color="#00A60C" size={48} speedMultiplier={0.6} />
    </div>
  );
};

export default Loader;

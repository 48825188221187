import axios, { AxiosRequestConfig } from 'axios';
import config from '@/config';
import 'react-notifications-component/dist/theme.css';

export const http = axios.create({
  baseURL: `${config.apiServer}/api`
});

console.log('www test env ====> ', config.apiServer);

http.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('dd_token');
  if (token) {
    const { accessToken } = JSON.parse(token);
    if (!window.location.pathname.includes('/login')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.common['Authorization'] = `bearer ${accessToken}`;
    }
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    // const tokens = localStorage.getItem('dd_token');
    // if (tokens) {
    //   const { accessToken } = JSON.parse(tokens);

    //   const now = new Date();
    //   const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    //   const newAccessTokenExpirationTime = new Date(utcNow);
    //   newAccessTokenExpirationTime.setUTCHours(newAccessTokenExpirationTime.getUTCHours() + 1);

    //   accessToken &&
    //     newAccessTokenExpirationTime &&
    //     localStorage.setItem(
    //       'dd_token',
    //       JSON.stringify({
    //         accessToken: accessToken,
    //         accessTokenExpiresAt: newAccessTokenExpirationTime
    //       })
    //     );
    // }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest?.url?.includes('sessionToken')) {
      setTimeout(() => {
        window.location.href = '/';
      }, 30000);
    }
    if (error?.response?.status === 400) {
      const tokens = localStorage.getItem('dd_token');
      if (tokens) {
        const { accessTokenExpiresAt } = JSON.parse(tokens);

        // Add client side time check to compare to token expiry time from BE
        const now = new Date();
        const currentTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        if (currentTime > accessTokenExpiresAt) {
          clearAndReload(); // log user out only if the token stored in the front end expired
        }
      }
    }
  }
);

export function clearAndReload() {
  if (!window.location?.pathname?.includes('/login')) {
    localStorage.removeItem('employee_filter');
    localStorage.removeItem('dd_token');
    localStorage.removeItem('dd_account');
    localStorage.removeItem('dd_isSigned');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('dd_first_login');
    localStorage.removeItem('user_filter');
  }
}

function get(
  url: string,
  headers: Record<string, any> = {},
  params: Record<string, any> = {},
  config: Record<string, any> = {}
) {
  return http.get(url, {
    params,
    headers,
    ...config
  });
}

function post<T>(
  url: string,
  data: Record<string, any> = {},
  headers: Record<string, any> = {},
  options: Record<string, any> = {}
) {
  return http.post<T>(url, data, {
    ...options,
    headers
  });
}

function put(
  url: string,
  data: Record<string, any> = {},
  headers: Record<string, any> = {},
  options: Record<string, any> = {}
) {
  return http.put(url, data, {
    ...options,
    headers
  });
}

function remove(url: string, data = {}, headers = {}, options = {}) {
  return http.delete(url, {
    ...options,
    headers,
    data
  });
}

export default {
  http,
  get,
  post,
  put,
  remove
};

import React, { ComponentProps, useState } from 'react';
import Input from '@/components/common/Input';
import Icon from '@/components/common/Icon';

const PasswordInput = ({ ...rest }) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  return (
    <Input
      {...rest}
      type={visiblePassword ? 'text' : 'password'}
      endAdornment={
        <span
          className="mr-2 hover:cursor-pointer"
          onClick={() => setVisiblePassword(!visiblePassword)}>
          <Icon
            className="text-greyscale-mediumGrey"
            name={visiblePassword ? 'closed-eye' : 'eye'}
          />
        </span>
      }
    />
  );
};

export default PasswordInput;

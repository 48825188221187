import React, { createContext, useContext, useState } from 'react';

type PlayerContextType = {
    customerName: string | null;
    setCustomerName: React.Dispatch<React.SetStateAction<string | null>>;
};

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

export const usePlayerContext = (): PlayerContextType => {
    const context = useContext(PlayerContext);
    if (!context) {
        throw new Error('usePlayerContext must be used within a PlayerProvider');
    }
    return context;
};

interface PlayerProviderProps {
    children: React.ReactNode;
}

export const PlayerProvider: React.FC<PlayerProviderProps> = ({ children }) => {
    const [customerName, setCustomerName] = useState<string | null>(null);

    return (
        <PlayerContext.Provider value={{ customerName, setCustomerName }}>
            {children}
        </PlayerContext.Provider>
    );
};

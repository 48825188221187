import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@/components/common/Table';
import { TableColumn } from '@/type/table';
import Icon from '@/components/common/Icon';
import { Store, NOTIFICATION_TYPE  } from 'react-notifications-component';
import Button from '@/components/common/Button';
import { useAppDispatch, useAppSelector } from '@/store';
import { playersSelector } from '@/store/player';
import IconButton from '@/components/common/IconButton';
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton
} from '@mui/x-data-grid-pro';
import { commonDateFormat } from '@/utils/date';
// import { getPlayers } from '@/store/player/actions';
import { getPlayers } from '@/service/player.service';
import { VerificationStatus, PlayerStatus } from '@/components/common/Chips';
import { usePlayerContext } from '@/contexts/playerContext';
import UploadCSVModal from '../../modal/UploadCSVModal';
import DeletePlayersModal from '../../modal/DeletePlayersModal';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { deleteMultiplePlayers } from '@/service/player.service';
import { SearchPlayerItem, PlayerItem } from '@/type/player';
import MoonLoader from 'react-spinners/MoonLoader';

type Props = {
  setGridReference?: any;
  gridReference: any;
  selectionModel: any;
  setSelectionModel: any;
};

interface ExtendedPlayerItem extends PlayerItem {
  customerID: number;
  userName: string;
}

interface SelectedPlayer {
  customerID: number;
  loginName: string;
}

// Define alert component
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const showNotification = (
  title: string,
  type: NOTIFICATION_TYPE, // Use the specific NOTIFICATION_TYPE instead of string
  message?: string
) => {
  Store.addNotification({
    title: title,
    message: message || '',
    type: type, // Type is now of the correct NOTIFICATION_TYPE
    insert: 'top',
    container: 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
};


const PlayersTable: React.FC<Props> = ({
  setGridReference,
  gridReference,
  selectionModel,
  setSelectionModel
}) => {
  const dispatch = useAppDispatch();
  const searchRef = useRef(null);
  const CustomMenuIcon = () => <Icon name="more-vert" />;
  
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [searchDisabled, setSeachDisabled] = useState(false);

  const { setCustomerName } = usePlayerContext();

  // State to hold players data locally
  const [players, setPlayers] = useState<SearchPlayerItem[]>([]);
  
  const navigate = useNavigate();

  // Set extracted CSV data
  const [csvData, setCsvData] = useState<string[]>([]);

  const handleCSVDataExtracted = (data: string[]) => {
    setCsvData(data);
    showNotification('CSV file has been successfully added! ✅', 'success');
  };

  useEffect(() => {
    console.log("CSV data updated: ", csvData);
  }, [csvData]);

  // Track if CSV was uploaded correctly
  const [isCSVUploaded, setIsCSVUploaded] = useState(false);

  // Keep track of players selected for deletion
  const [playersSelected, setPlayersSelected] = useState<SelectedPlayer[]>([]);
  // Track if delete confirmation is sent from DeletePlayers modal -> Triggers delete API call
  const [isDeletePlayers, setIsDeletePlayers] = useState(false);
  // Track if players have been deleted successfully
  const [arePlayersDeleted, setArePlayersDeleted] = useState(false);


  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCSVUploaded(false);
    setIsDeletePlayers(false);
  };
  
  const [isUploadCSVModalOpen, setIsUploadCSVModalOpen] = useState(false);
  const [isDeletePlayersModalOpen, setIsDeletePlayersModalOpen] = useState(false);

  console.log("Selection model: ", selectionModel);
  
  const playerColumns: TableColumn[] = [
    {
      field: 'customerID',
      label: 'Customer ID',
      cellClassName: 'max-w-[80px]',
      tooltip: true,
      render: (row: any) => (
        <div
          onClick={() => {
            console.log('Row info log:', row);  // Debugging line
            setCustomerName(row.row.userName);
            navigate(`/players/${row.value}`);
          }}
          className="text-sm cursor-pointer text-left text-ctascale-darkMain font-semibold">
          {row.value}
        </div>
      )
    },
    {
      field: 'customerName',
      label: 'Name'
    },
    {
      field: 'userName',
      label: 'User name'
    },
    {
      field: 'email',
      label: 'Email'
    },
    // {
    //   field: 'statusName',
    //   label: 'Player Status',
    //   render: (row: any) => PlayerStatus(row.value)
    // },
    {
      field: 'verificationStatus',
      label: 'Ver. Status',
      render: (row: any) => VerificationStatus(row.value)
    },
    {
      field: 'reg_Date',
      label: 'Registration Day',
      render: (row: any) => <>{commonDateFormat(row.value, true)}</>
    }
  ];
  
  // Define function to search for players
  const fetchPlayers = async (searchString: string) => {
    setSeachDisabled(true);
    try {
      const response: SearchPlayerItem[] = await getPlayers({ searchBar: searchString });
      
      // Assuming the API returns the players list directly
      setPlayers(response);
      showNotification('Players data fetched successfully!', 'success');
      setSeachDisabled(false);
    } catch (error) {
      console.error('Error fetching players:', error);
      showNotification('Failed to fetch players data!', 'danger');
    }
  };

  useEffect(() => {
    // Generate the string from csvData and fetch players
    if (csvData.length > 0) {
      const searchString = csvData.join(', ');
      fetchPlayers(searchString);
    }
  }, [csvData]);
  
  // useeffect to log localStorage.getItem('isAdmin') to see its value
  useEffect(() => {
    console.log('isAdmin:', localStorage.getItem('isAdmin'));
    if (localStorage.getItem('isAdmin') === 'true') {
      setUserIsAdmin(true);
    }
  }, []);

  // const players = useAppSelector(playersSelector);
  
  // Update playersSelected on selectionModel changes
  useEffect(() => {
    
    if (players && selectionModel) {
      // const extendedPlayers = players as ExtendedPlayerItem[];
      
      const selectedPlayers = players
      .filter(player => selectionModel.includes(player.customerID))
      .map(selectedPlayer => ({
        customerID: selectedPlayer.customerID,
        loginName: selectedPlayer.userName  // userName is mapped to loginName
      }));
      console.log('Players selected for deletion: ', selectedPlayers)
      setPlayersSelected(selectedPlayers);
    }
  }, [selectionModel, players]);
  
  // Track if Delete button from modal has been clicked, call API to delete
  useEffect(() => {
    const deletePlayers = async () => {
      try {
        // Since we expect a message now, we store it in a variable
        const transformedArray = selectionModel.map((item:string) => {
          const [customerID, loginName] = item.split(',');
          return {
            customerID: parseInt(customerID),
            loginName: loginName
          };
        });
        const { message } = await deleteMultiplePlayers(transformedArray);
        console.log("Players deleted successfully:", message);
        showNotification('Players have been successfully deleted! ✅', 'success');
        
        // Extract the customerIDs from playersSelected
        const selectedCustomerIDs = playersSelected.map(p => p.customerID.toString());

        // Filter out the selectedCustomerIDs from csvData
        const updatedSearch = csvData.filter(data => !selectedCustomerIDs.includes(data));

        // After deletion, fetch players again to update the table
        fetchPlayers(updatedSearch.join(', '));
      } catch (error) {
        console.error("Failed to delete players", error);
        showNotification('Failed to delete players! ❌', 'danger');
      }
    };
  
    if (isDeletePlayers) {
      deletePlayers();
      setIsDeletePlayers(false);
    }
  }, [isDeletePlayers, playersSelected, setArePlayersDeleted]);
  
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-between">
        <div className="flex justify-center items-center gap-3">
          <GridToolbarQuickFilter
            inputRef={searchRef}
            placeholder="Search by Username, customerID, email"
            className="mui-grid-searchbar"
          />
          <Button
            variant="bg-beautiful"
            className="w-[106px] !px-6 !py-3"
            disabled={searchDisabled}
            onClick={async () => {     
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (searchRef?.current?.value) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const searchString = searchRef.current.value;
                await fetchPlayers(searchString);
              }
            }}>
              {searchDisabled?
            <div
              className={'max-h-[24px] w-14 flex  justify-center items-center p-1'}>
              <MoonLoader color="#00A60C" size={24} speedMultiplier={0.6} />
            </div>
            :`${' '}
            Search${' '}`}
            
          </Button>
        </div>
        <div className="flex p-2">
          { (selectionModel.length != 0) ?
            <>
              {userIsAdmin && (
                <Button className="mr-4" variant="secondary" onClick={() => setIsDeletePlayersModalOpen(true)}>
                  Delete selection
                </Button>
              )}
              <Button className="mr-4" variant="bg-muted" onClick={() => setSelectionModel([])}>
                Clear
              </Button>          
            </> 
            :
            <Button className="mr-4" variant="bg-beautiful" onClick={() => setIsUploadCSVModalOpen(true)}>
              Upload CSV
            </Button>
          }
          <GridToolbarColumnsButton
            disableRipple
            startIcon={<Icon name="columns" />}
            className="!ml-2.5 !bg-transparent !min-w-0"
          />
        </div>
      </GridToolbarContainer>
    );
  }

  console.log('Player data: ', players);

  return (
    <div className="rounded-xl">
      <Table
        pagination
        selectionModel={selectionModel}
        checkboxSelection={true}
        setGridReference={setGridReference}
        setSelectionModel={setSelectionModel}
        columns={playerColumns}
        data={players || []}
        CustomToolbar={CustomToolbar}
        CustomMenuIcon={CustomMenuIcon}
        origin="players"
      />
      <UploadCSVModal
        onCSVDataExtracted={handleCSVDataExtracted}
        setIsCSVUploaded={setIsCSVUploaded}
        open={isUploadCSVModalOpen}
        onClose={() => setIsUploadCSVModalOpen(false)}
      />
      <DeletePlayersModal
        setIsDeletePlayers={setIsDeletePlayers}
        open={isDeletePlayersModalOpen}
        onClose={() => setIsDeletePlayersModalOpen(false)}
      />

    </div>
  );
};

export default PlayersTable;

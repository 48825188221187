import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';

const selectSelf = (state: RootState) => state.player;

export const playersSelector = createSelector(
  selectSelf,
  (state) => state.players
);

export const transactionsSelector = createSelector(
  selectSelf,
  (state) => state.transactions
);

export const playerFilterDataSelector = createSelector(
  selectSelf,
  (state) => state.filter
);

export const transactionFilterDataSelector = createSelector(
  selectSelf,
  (state) => state.transactionFilter
);

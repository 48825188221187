import React, { useState, useRef, useCallback, DragEvent, useEffect, memo } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import CloseIcon from '@/components/common/CloseButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@/components/common/Button';
// import { postUploadDocumentFile  } from '@/service/player.service';
import { PlayerDocument } from '@/type/player';

type Props = ModalProps & {
  setIsDeletePlayers: (value: boolean) => void;
};

const DeleteSinglePlayerModal: React.FC<Props> = ({
  open,
  onClose,
  setIsDeletePlayers
}) => {

  const handleClick = () => {
    setIsDeletePlayers(true);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} smallScreen={true} padding='0px'>
      <div className='px-4 py-2'>
        <div className='flex justify-end mb-4'>
          <CloseIcon onClick={onClose} />
        </div>
        <div className='flex flex-col pb-12 pl-12 pr-12 gap-5'>
          <h2 className="text-[32px] font-['Inter'] font-bold text-left leading-10 tracking-wide">
            Are you sure you want to delete this profile?
          </h2>

          <div className="grow shrink basis-0 text-neutral-500 text-base font-semibold font-['Inter'] leading-normal tracking-wide">You will permanently delete these profiles.</div>
        
          <div className="justify-end items-start gap-4 mt-2 inline-flex">
            <Button onClick={onClose} variant='bg-muted' className=''>
              Cancel
            </Button>
            <Button onClick={handleClick} variant='danger' className=''>
              Yes, delete
            </Button>
          </div>
          
        </div>

      </div>
    </Modal>
  );
};

export default DeleteSinglePlayerModal;

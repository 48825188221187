import React, { useRef } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Button from '@/components/common/Button';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import { Store } from 'react-notifications-component';
import ErrorMessage from '@/components/common/ErrorMessage';
import PasswordInput from '@/components/common/PasswordInput';
import Checker from '@/components/common/Checker';
import * as authApi from '@/service/auth.service';
import { setSigned } from '@/store/auth';

type InitialValue = {
  password: string;
  confirm: string;
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&.;^<>{}'+\-"\\/~`])[A-Za-z\d#@$!%*?&.;^<>{}'+\-"\\/~`]{12,}$/,
      'Use at least 12 characters, with uppercase and lowercase letters, numbers and at least one special character'
    ),
  confirm: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.')
});

const passwordCheckers = [
  {
    label: 'Min 12 characters',
    pattern: /[\w|\W]{12}/
  },
  {
    label: 'Min 1 uppercase',
    pattern: /[A-Z]/
  },
  {
    label: 'Min 1 lowercase',
    pattern: /[a-z]/
  },
  {
    label: 'Min 1 numbers',
    pattern: /\d/
  },
  {
    label: 'Min 1 special character',
    pattern: /[@$!%*?&.]/
  }
];

const initialValue: InitialValue = {
  password: '',
  confirm: ''
};

type Props = Partial<ModalProps> & {
  onSuccess?: () => void;
  sessionToken?: string;
  isFirstPasswordReset?: boolean;
};

const ResetPasswordModal: React.FC<Props> = ({
  open = true,
  onClose = () => null,
  sessionToken,
  isFirstPasswordReset
}) => {
  const logError = useRef(null);
  const dispatch = useDispatch();
  const handleSubmit = async (
    values: InitialValue,
    { setSubmitting }: FormikHelpers<InitialValue>
  ) => {
    const logErr: any = logError.current;
    try {
      if (isFirstPasswordReset) {
        setSubmitting(true);
        await authApi
          .resetFirstPassword({
            newPassword: values.password
          })
          .then(() => {
            onClose();
            setSigned(true);
            dispatch(setSigned(true));
            localStorage.setItem('dd_isSigned', 'true');
          })
          .catch((error: any) => {
            console.log(error);
          });
        setSubmitting(false);
      } else {
        setSubmitting(true);
        await authApi
          .resetPassword({
            token: sessionToken,
            newPassword: values.password
          })
          .then(() => {
            onClose();
          });
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      Store.addNotification({
        title: 'There was a problem changing your password',
        message: '',
        type: 'danger',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose} origin="resetPassword">
      <div className="mx-auto max-w-[583px]">
        <h2 className="text-title mb-5 font-semibold text-center">
          Set your new password
        </h2>
        <p className="text-lg text-center leading-6 mx-auto max-w-[414px] mb-[34px]">
          Create a new password for your own
        </p>

        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={handleSubmit}>
          {({
            errors,
            touched,
            values,
            handleBlur,
            handleChange,
            isValid,
            isSubmitting
          }) => {
            return (
              <Form className="max-w-[399px] mx-auto">
                <div className="mb-7">
                  <PasswordInput
                    className="placeholder-grey-disabled"
                    placeholder="Enter new password"
                    name="password"
                    error={touched.password && !!errors.password}
                    success={!!values.password && isValid}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <PasswordInput
                  className="placeholder-grey-disabled"
                  placeholder="Enter confirm password"
                  name="confirm"
                  error={touched.confirm && !!errors.confirm}
                  success={!!values.confirm && isValid}
                  value={values.confirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="confirm" />
                <div className="p-3 mb-10">
                  {passwordCheckers.map((item, index) => (
                    <Checker
                      key={index}
                      status={item.pattern.test(values.password)}
                      label={item.label}
                    />
                  ))}
                </div>

                <div className="flex-col justify-center gap-5 align-middle">
                  <Button
                    className="w-full"
                    type="submit"
                    variant="bg-beautiful"
                    disabled={!isValid || isSubmitting}
                    onClick={() => handleSubmit}>
                    Save
                  </Button>
                  <pre
                    ref={logError}
                    className="block text-xs py-3 text-red-500 text-center"></pre>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;

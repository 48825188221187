import React from 'react';
import clsx from 'clsx';

type Props = {
  status: boolean;
  label: string;
};

const Checker: React.FC<Props> = ({ status, label }) => {
  return (
    <div
      className={clsx(
        'text-sm mb-1.5 flex items-center',
        status && 'text-successscale-dark'
      )}>
      <div className="w-[18px] mr-2 text-center">{status ? '✅' : '•'}</div>
      {label}
    </div>
  );
};

export default Checker;

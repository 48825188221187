import {
  ALPHA_DASH_REGEX,
  NUMERIC_DASH_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
  DATE_REGEX
} from '@/components/common/Regex';

export const isDateOfBirthProblematic = (birthdate: any) => {
  if (!DATE_REGEX.test(birthdate)) {
    return true;
  }

  if (
    birthdate?.substring(0, 2) &&
    birthdate?.substring(3, 5) &&
    birthdate?.substring(6, 10)
  ) {
    const dd = parseInt(birthdate.substring(0, 2));
    const mm = parseInt(birthdate.substring(3, 5));
    const yyyy = parseInt(birthdate.substring(6, 10));

    if (dd > 31 || dd < 1) return true;
    if (mm > 12 || mm < 1) return true;
    if (yyyy < 1900 || yyyy > new Date().getFullYear()) return true;
  }

  return false;
};

export const isContinueDisabled = (
  name: any,
  surname: any,
  phone: any,
  email: any,
  birthdate: any
) => {
  return !ALPHA_DASH_REGEX.test(name) ||
    !ALPHA_DASH_REGEX.test(surname) ||
    !PHONE_REGEX.test(phone) ||
    !EMAIL_REGEX.test(email) ||
    isDateOfBirthProblematic(birthdate)
    ? true
    : false;
};

import Icon from '@/components/common/Icon';
import Input from '@/components/common/Input';

type Props = {
  searchBar: string;
  setSearchBar: any;
  origin?: string;
};

const SearchBarFilter: React.FC<Props> = ({
  searchBar,
  setSearchBar,
  origin
}) => {
  return (
    <Input
      className="w-full mt-2.5 py-7 bg-[#EFEFEF] border-0"
      placeholder={
        origin === 'users'
          ? 'Search for a user (user ID/ Name/ Sendtag/ Email/ phone number)'
          : 'Search employee'
      }
      value={searchBar}
      startAdornment={<Icon className="ml-3.5 text-grey" name="search" />}
      onChange={(e: any) => setSearchBar(e.target.value)}
    />
  );
};
export default SearchBarFilter;

import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Table from '@/components/common/Table';
import Status from '@/components/common/Status';
import AvatarPlaceholder from '@/assets/images/avatar-placeholder.png';
import { TableColumn } from '@/type/table';
import { useAppSelector } from '@/store';
import { employeesSelector } from '@/store/employee';
import { VerificationStatus } from '@/components/common/Chips';
import lookup from 'country-code-lookup';
import ReactCountryFlag from 'react-country-flag';
import { useRef, useState, useEffect, useMemo } from 'react';
import IconButton from '@/components/common/IconButton';
import Button from '@/components/common/Button';
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton
} from '@mui/x-data-grid-pro';

const employeeColumns: TableColumn[] = [
  {
    field: 'employeeId',
    label: 'EmployeeId',
    cellClassName: 'max-w-[80px]',
    tooltip: true,
    render: (row: any) => (
      <div className="flex items-center justify-center">
        {localStorage.getItem('isAdmin') != 'true' ? (
          <div>{row.value}</div>
        ) : (
          <Link
            to={`/employee/${row.value}`}
            className="text-sm text-left text-ctascale-darkMain font-semibold">
            {row.value}
          </Link>
        )}
      </div>
      // <Link
      //   to={`/employee/${row.avatar}`}
      //   className="text-sm text-left text-primary font-semibold">
      //   {row.name}
      // </Link>
    )
  },
  {
    field: 'firstName',
    label: 'Name'
  },
  {
    field: 'lastName',
    label: 'Surname'
  },
  {
    field: 'department',
    label: 'Department'
  },
  {
    field: 'birthDate',
    label: 'Birth day'
  },
  {
    field: 'city',
    label: 'City'
  },
  {
    field: 'address',
    label: 'Address'
  },
  {
    field: 'countryCode',
    label: 'Country',
    render: (row: any) => (
      <div className="flex items-end justify-start">
        <ReactCountryFlag
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          countryCode={lookup.byCountry(row.value).iso2}
          style={{
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '50%',
            marginRight: '0.5rem'
          }}
          svg
        />
        <h1>{row.value}</h1>
      </div>
    )
  },
  {
    field: 'email',
    label: 'Email'
  },
  {
    field: 'status',
    label: 'Status',
    render: (row: any) => VerificationStatus(row.value)
  },
  {
    field: 'phoneNumber',
    label: 'Phone number'
  },
  {
    field: 'employeeContractType',
    label: 'Type'
  }
];

const EmployeeTable = () => {
  const employees = useAppSelector(employeesSelector);
  const searchRef = useRef(null);

  const CustomMenuIcon = () => <Icon name="more-vert" />;

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-end w-full">
        <div className="flex p-2">
          <GridToolbarColumnsButton
            disableRipple
            startIcon={<Icon name="columns" />}
            className="!ml-2.5 !bg-transparent !min-w-0"
          />
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="rounded-xl ">
      <Table
        origin="employees"
        CustomToolbar={CustomToolbar}
        CustomMenuIcon={CustomMenuIcon}
        pagination
        columns={employeeColumns}
        data={employees || []}
      />
    </div>
  );
};

export default EmployeeTable;

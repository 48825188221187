import React from 'react';
import InputUnstyled from '@mui/base/InputUnstyled';
import { InputUnstyledProps } from '@mui/base';
import clsx from 'clsx';
import './style.css';

type Props = InputUnstyledProps & {
  error?: boolean;
  success?: boolean;
};

const Input: React.FC<Props> = ({ className, error, success, ...rest }) => {
  return (
    <InputUnstyled
      className={clsx('input', className, {
        error: error,
        success: success
      })}
      {...rest}
    />
  );
};

export default Input;

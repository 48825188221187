import React, { useState } from 'react';
import LoginEmailSentModal from '@/components/widgets/modal/LoginEmailSentModal';
import {
  Formik,
  Form,
  FormikHelpers,
  FormikErrors,
  FormikTouched
} from 'formik';
import SignedOutModal from '@/components/widgets/modal/SignedOutModal';
import LinkTimedOutModal from '@/components/widgets/modal/LinkTimedOutModal';
import { EMAIL_REGEX } from '@/components/common/Regex';
import * as Yup from 'yup';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
import ForgotPasswordModal from '@/components/widgets/modal/ForgotPasswordModal';
import ResetSuccessModal from '@/components/widgets/modal/ResetSuccessModal';
import PasswordInput from '@/components/common/PasswordInput';
import { LoginValue } from '@/type/player';
import { useAppDispatch } from '@/store';
import { login } from '@/store/auth';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Enter email first')
    .matches(EMAIL_REGEX),
  password: Yup.string().required('Enter a password').trim('Enter a password')
});

const initialValues: LoginValue = {
  email: '',
  password: ''
};

type Props = {
  isUserSignedOutModalOpen: boolean;
  setIsUserSignedOutModalOpen: any;
  isEmailLinkExpired: boolean;
  setIsEmailLinkExpired: any;
};

const Login: React.FC<Props> = ({
  isUserSignedOutModalOpen,
  setIsUserSignedOutModalOpen,
  isEmailLinkExpired,
  setIsEmailLinkExpired
}) => {
  const dispatch = useAppDispatch();

  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [isShowForgotPasswordModal, setIsShowForgotPasswordModal] =
    useState<boolean>(false);
  const [isShowLoginEmailSentModal, setIsShowLoginEmailSentModal] =
    useState<boolean>(false);
  const [emailUsedToLogin, setEmailUsedToLogin] = useState();
  const [passwordUsedToLogin, setPasswordUsedToLogin] = useState();
  const [error, setError] = useState('');

  const handleSubmit = async (
    values: LoginValue,
    { setSubmitting }: FormikHelpers<LoginValue>
  ) => {
    setError('');
    setSubmitting(true);
    const loginDispatch = await dispatch(login(values));
    if (loginDispatch.meta.requestStatus === 'rejected') {
      setError(loginDispatch.payload);
    } else {
      setIsShowLoginEmailSentModal(true);
    }
    setSubmitting(false);
  };

  return (
    <div className="flex-1 relative ml-10">
      <h1 className="text-heading mb-3 font-bold">KYC Backoffice</h1>
      <div className="w-full max-w-[773px] mb-3">
        <p className="text-2xl mb-8 font-bold text-[#716B70] tracking-[0.75px]">
          Log in with the email and password which you have confirmed for this
          platform from 10bet.
        </p>

        {error && (
          <div
            className="flex w-[456px] py-4 px-6 justify-center items-start gap-2.5 rounded-lg mb-5 bg-[#FFECFC] font-[650] tracking-[0.75px]"
            style={{ boxShadow: '0px 15px 20px 0px rgba(0, 0, 0, 0.04)' }}>
            Wrong email or password! Please, try again ❌
          </div>
        )}
        <div className="shadow-paper rounded-2xl bg-white p-5 w-[456px]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={handleSubmit}>
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              isValid,
              isSubmitting
            }) => {
              return (
                <Form>
                  <div className="mb-3">
                    <Input
                      placeholder="Email Address"
                      name="email"
                      error={touched.email && !!errors.email}
                      success={!!values.email && isValid}
                      value={values.email}
                      onChange={(event: any) => {
                        handleChange(event);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setEmailUsedToLogin(event.target.value);
                        setError('');
                      }}
                      onBlur={handleBlur}
                    />
                    <p className="text-errorscale-dark">
                      {touched.email && errors.email && 'Invalid email address'}
                    </p>
                  </div>
                  <div className="mb-2">
                    <PasswordInput
                      placeholder="Password"
                      name="password"
                      error={touched.password && !!errors.password}
                      success={!!values.password && isValid}
                      value={values.password}
                      onChange={(event: Event) => {
                        handleChange(event);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setPasswordUsedToLogin(event.target.value);
                        setError('');
                      }}
                      onBlur={handleBlur}
                    />
                    <p className="text-errorscale-dark">
                      {touched.password &&
                        errors.password &&
                        'Invalid password'}
                    </p>
                  </div>
                  <div className="flex">
                    <div
                      className="text-xs text-[#252023] font-medium cursor-pointer mb-5"
                      onClick={() => setIsShowForgotPasswordModal(true)}>
                      Forgot your password?
                    </div>
                  </div>

                  <Button
                    className={`w-full`}
                    variant="bg-beautiful"
                    type="submit"
                    disabled={isSubmitting || !isValid}>
                    Log in
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <ForgotPasswordModal
        open={isShowForgotPasswordModal}
        onClose={() => setIsShowForgotPasswordModal(false)}
        onSuccess={() => setIsShowSuccessModal(true)}
      />

      <ResetSuccessModal
        open={isShowSuccessModal}
        onClose={() => setIsShowSuccessModal(false)}
      />

      <LoginEmailSentModal
        open={isShowLoginEmailSentModal}
        emailUsedToLogin={emailUsedToLogin || ''}
        passwordUsedToLogin={passwordUsedToLogin || ''}
        onClose={() => null}
      />

      {isUserSignedOutModalOpen && (
        <SignedOutModal
          open={isUserSignedOutModalOpen}
          onClose={() => setIsUserSignedOutModalOpen(false)}
        />
      )}

      {isEmailLinkExpired && (
        <LinkTimedOutModal
          open={isEmailLinkExpired}
          onClose={() => setIsEmailLinkExpired(false)}
        />
      )}
    </div>
  );
};

export default Login;

import React from 'react';
import clsx from 'clsx';

type Props = React.PropsWithChildren & {
  className?: string;
  onClick: () => void;
  small?: boolean;
  disabled?: boolean;
};

const IconButton: React.FC<Props> = ({
  className,
  onClick,
  small,
  disabled,
  children
}) => {
  return (
    <button
      className={clsx(
        'w-10 h-10 rounded-lg flex justify-center items-center hover:cursor-pointer flex-shrink-0 disabled:opacity-20',
        small && 'w-6 h-6',
        className
      )}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default IconButton;

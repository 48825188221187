import { createSlice } from '@reduxjs/toolkit';
import { login } from './actions';
import { AuthState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse } from '@/type/api';

const initialState: AuthState = {
  isSigned: false,
  user: null
};

const setAuthState = (state: AuthState, data: { payload: LoginResponse }) => {
  const {
    id,
    firstName,
    lastName,
    email,
    resetPasswordToken,
    hasLoggedIn,
    accessToken,
    refreshToken,
    accessTokenExpiresAt,
    refreshTokenExpiresAt
  } = data.payload;

  state.isSigned = false;

  state.user = {
    id,
    firstName,
    lastName,
    email,
    resetPasswordToken,
    hasLoggedIn
  };

  id &&
    firstName &&
    lastName &&
    email &&
    localStorage.setItem(
      'dd_account',
      JSON.stringify({
        id,
        firstName,
        lastName,
        email,
        resetPasswordToken,
        hasLoggedIn
      })
    );

  accessToken &&
    accessTokenExpiresAt &&
    localStorage.setItem(
      'dd_token',
      JSON.stringify({
        accessToken,
        accessTokenExpiresAt
      })
    );
};

const setSigned = (state: AuthState, action: PayloadAction<boolean>) => {
  state.isSigned = action.payload;
};

const logout = (state: AuthState) => {
  state.isSigned = false;
  localStorage.removeItem('dd_token');
  localStorage.removeItem('dd_account');
  localStorage.removeItem('employee_filter');
  localStorage.removeItem('dd_isSigned');
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState,
    setSigned,
    logout
  },
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      setAuthState(state, { payload });
    });
  }
});

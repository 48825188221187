import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import ResetPasswordModal from '@/components/widgets/modal/ResetPasswordModal';
import { isResetPasswordTokenValid } from '@/service/employee.service';

type Props = {
  setIsEmailLinkExpired: any;
};

const ResetPasswordToken: React.FC<Props> = ({ setIsEmailLinkExpired }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const passwordToken = location.pathname.split('/')[2];
  const [isShowResetPasswordModal, setIsShowResetPasswordModal] =
    useState(true);

  useEffect(() => {
    (async () => {
      try {
        await isResetPasswordTokenValid(passwordToken);
      } catch (e) {
        setIsShowResetPasswordModal(false);
        navigate('/');
        setIsEmailLinkExpired(true);
      }
    })();
  }, []);

  const handleCloseResetPasswordModal = () => {
    setIsShowResetPasswordModal(false);
    setTimeout(() => {
      Store.addNotification({
        title: 'Password changed successfully! ✅',
        message: '',
        type: 'success',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }, 500);
    navigate('/');
  };

  return (
    <ResetPasswordModal
      open={isShowResetPasswordModal}
      isFirstPasswordReset={false}
      sessionToken={passwordToken}
      onClose={handleCloseResetPasswordModal}
    />
  );
};

export default ResetPasswordToken;

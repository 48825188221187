import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import './index.css';
import { ReactNotifications } from 'react-notifications-component';
import LogRocket from 'logrocket';


// if (!userIsAdmin) {
LogRocket.init('gbttlc/10bet');
  // LogRocket.identify('userId1', {email: 'email1', name: 'Name 1'});
  // LogRocket.identify('userId2', {email: 'email2', name: 'Name2'});
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ReactNotifications />
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

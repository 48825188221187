import React from 'react';
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import clsx from 'clsx';
import './style.css';

type Props = ButtonUnstyledProps & {
  variant?: 'primary' | 'secondary' | 'bg-beautiful' | 'bg-muted' | 'danger';
  isDisabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode; 
};

const Button: React.FC<Props> = ({
  className,
  variant = 'primary',
  isDisabled,
  startIcon,
  endIcon,
  children,
  ...rest
}) => {
  return (
    <ButtonUnstyled
      disabled={isDisabled}
      className={clsx('button', variant, className)}
      {...rest}
    >
      {startIcon && <span className="button-start-icon">{startIcon}</span>}
      {children}
      {endIcon && <span className="button-end-icon">{endIcon}</span>}
    </ButtonUnstyled>
  );
};

export default Button;

import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getPlayerDocument } from '@/service/player.service';
import PersonalInformation from '@/components/widgets/sections/PersonalInformation';
import { getPlayerInfo, getPlayerDocumentList } from '@/service/player.service';
import Loader from '@/components/widgets/loaders/Loader';
import { PlayerInformation, PlayerDocumentList } from '@/type/player';
import DocumentSection from '@/components/widgets/sections/DocumentSection';
import HeaderSection from '@/components/widgets/sections/HeaderSection';
import DocumentTable from '@/components/widgets/tables/DocumentsTable';
import { usePlayerContext } from '@/contexts/playerContext';

const PlayerDetail = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageSectionLoading, setImageSectionLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<PlayerDocumentList>(
    [] as PlayerDocumentList
  );
  const [profileInfo, setProfileInfo] = useState<PlayerInformation>(
    {} as PlayerInformation
  );
  const [initialPlayerName, setInitialPlayerName] = useState('');

  // Keep track on whether documents have been uploaded or modified
  const [isDocumentModified, setIsDocumentModified] = useState(false);

  const { customerName } = usePlayerContext();

  useEffect(() => {

    if (params.id && customerName) {
      const payload = { customerId: params.id, loginName: customerName };
      setLoading(true);
      Promise.all([getPlayerInfo(payload), getPlayerDocumentList(payload)])
        .then(([info, documentlist]) => {
          setProfileInfo(info?.[0]);
          setDocumentList(documentlist);
          console.log(documentlist);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id]);

  useEffect(() => {
    const fetchDocumentList = async () => {
      if (isDocumentModified && params.id && customerName) {
        const payload = { customerId: params.id, loginName: customerName };
        try {
          const response = await getPlayerDocumentList(payload);
          setDocumentList(response);
        } catch (error) {
          console.error('Error updating document list', error);
        }
  
        setIsDocumentModified(false);
      }
    };
  
    fetchDocumentList();
  }, [isDocumentModified, params.id, customerName]);
  

  useEffect(() => {
    (async () => {
      if (documentList && documentList.length > 0) {

        const loginName = customerName;

        if (!loginName) {
          console.error("loginName is not defined");
          return;  // Exit early if loginName is not available
        }

        setImageSectionLoading(true);
        await Promise.all(
          documentList.map(async (document) => {
            const payload = {
              customerId: document.customerId,
              fileName: document.fileName,
              loginName: loginName
            };

            return await getPlayerDocument(payload);
          })
        )
          .then((respDocs) => {
            setDocuments(respDocs);
            console.log(respDocs);
          })
          .finally(() => {
            setImageSectionLoading(false);
          });
      }
    })();
  }, [documentList?.length]);

  if (loading) return <Loader />;

  console.log("Profile info: ", profileInfo);
  console.log('setIsDocumentModified in PlayerDetail', typeof setIsDocumentModified);


  return (
    <>
      <HeaderSection pageName="Player profile" origin="playerDetails" />

      <div className="mx-6 sm:text-sm lg:text-base">
        <div className="mb-8">
          <PersonalInformation
            setProfileInfo={setProfileInfo}
            data={profileInfo}
            initialPlayerName={customerName || ''}
            playerId={params?.id ? params.id : ''}
          />
        </div>
        {imageSectionLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="mb-8">
              <h2 className="text-title font-medium mb-4 col-span-4">Photos</h2>
              {
                <DocumentSection
                  documentList={documentList || []}
                  documents={documents || []}
                  loading={imageSectionLoading}
                />
              }
            </div>
            <div className="mb-8">
              <h2 className="text-title font-medium mb-4 col-span-4">
                Document Management
              </h2>
              {
                <DocumentTable
                  setIsDocumentModified={setIsDocumentModified}
                  userName={profileInfo.userName}
                  userCustomerId={params?.id ?? ''}
                  documentList={documentList || []}
                  documents={documents || []}
                />
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlayerDetail;

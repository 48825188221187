import React, { useMemo, useState } from 'react';
import ToggleInput from '@/components/common/ToggleInput';
import { EmployeeDetails as EmployeeDetailsType } from '@/type/employee';
import EditEmployeeDropdownFilter from '@/components/widgets/filters/EditEmployeeDropdownFilter';
import {
  ALPHA_DASH_REGEX,
  EMAIL_REGEX,
  NUMERIC_DASH_REGEX,
  DATE_REGEX,
  PHONE_REGEX
} from '@/components/common/Regex';
import { isDateOfBirthProblematic } from '@/containers/Employees/helperFunctions';

type Props = {
  data: EmployeeDetailsType;
  setData: any;
  id: string | undefined;
  countryOptions: any;
  isEditScreen: boolean;
};

const EmployeeDetails: React.FC<Props> = ({
  data,
  id,
  setData,
  countryOptions,
  isEditScreen
}) => {
  const editableContactFields: Array<{
    field: keyof EmployeeDetailsType;
    label: string;
    isWrongFormat?: boolean;
    regex?: RegExp;
  }> = [
    {
      field: 'firstName',
      label: 'Name',
      regex: ALPHA_DASH_REGEX,
      isWrongFormat: !ALPHA_DASH_REGEX.test(data.firstName)
    },
    {
      field: 'lastName',
      label: 'Last Name',
      regex: ALPHA_DASH_REGEX,
      isWrongFormat: !ALPHA_DASH_REGEX.test(data.lastName)
    },
    {
      field: 'phoneNumber',
      label: 'Phone number',
      regex: PHONE_REGEX,
      isWrongFormat: !PHONE_REGEX.test(data.phoneNumber)
    },
    {
      field: 'email',
      label: 'Email address',
      regex: EMAIL_REGEX,
      isWrongFormat: !EMAIL_REGEX.test(data.email)
    },
    {
      field: 'birthDate',
      label: 'Birth date',
      isWrongFormat: isDateOfBirthProblematic(data.birthDate)
    }
  ];

  const editableAddressFields: Array<{
    field: keyof EmployeeDetailsType;
    label: string;
  }> = [
    {
      field: 'countryCode',
      label: 'Country'
    },
    {
      field: 'city',
      label: 'City'
    },
    {
      field: 'address',
      label: 'Address'
    }
  ];

  return (
    <>
      <div className="flex flex-col justify-around gap-8">
        <div className="grid grid-cols-2 gap-2">
          {editableContactFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                editable={isEditScreen && item.field !== 'email'}
                label={item.label}
                value={data[item.field]}
                regex={item.regex}
                isWrongFormat={item.isWrongFormat}
                // item.field === 'firstName' || item.field === 'lastName'
                //   ? .test(data[item.field])
                //   : item.field === 'phoneNumber'
                //   ? NUMERIC_DASH_REGEX.test(data[item.field])
                //   : item.field === 'birthDate'
                //   ? isDateOfBirthProblematic(data.birthDate)
                //   : false
                onChangeValue={(newVal) => {
                  setData({
                    ...data,
                    [item.field]:
                      item.field === 'birthDate'
                        ? newVal.length <= 10
                          ? (data['birthDate'].length === 1 ||
                              data['birthDate'].length === 4) &&
                            data['birthDate'].length < newVal.length
                            ? newVal + '-'
                            : newVal
                          : data['birthDate']
                        : newVal
                  });
                }}
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-2">
          {editableAddressFields.map((item, index) => (
            <div key={index}>
              {!isEditScreen ? (
                <ToggleInput
                  editable={isEditScreen}
                  label={item.label}
                  value={data[item.field]}
                  onChangeValue={(newVal) => {
                    setData({ ...data, [item.field]: newVal });
                  }}
                  country={
                    item.field === 'countryCode' ? data.countryCode : undefined
                  }
                />
              ) : item.field === 'countryCode' ? (
                <div className="form-row-input">
                  <EditEmployeeDropdownFilter
                    options={countryOptions}
                    label="Country"
                    selectedValue={data.countryCode}
                    searchable={true}
                    onChangeFilter={(newVal) => {
                      setData({ ...data, countryCode: newVal });
                    }}
                  />
                </div>
              ) : (
                <ToggleInput
                  editable={isEditScreen}
                  label={item.label}
                  value={data[item.field]}
                  onChangeValue={(newVal) => {
                    setData({ ...data, [item.field]: newVal });
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmployeeDetails;

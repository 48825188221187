import { createSlice } from '@reduxjs/toolkit';
import { getPlayers } from './actions';
import { PlayerState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { GetPlayerResponse } from '@/type/api';

const initialState: PlayerState = {
  filter: null,
  transactionFilter: null,
  players: null,
  transactions: null,
  employee: null
};

const setPlayers = (
  state: PlayerState,
  action: PayloadAction<GetPlayerResponse>
) => {
  state.players = action.payload;
};

export const slice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayers
  },
  extraReducers(builder) {
    builder.addCase(getPlayers.fulfilled, (state, action) => {
      setPlayers(state, action);
    });
  }
});

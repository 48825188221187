import React, { useEffect, PropsWithChildren, useState } from 'react';
import { PlayerDocumentList } from '@/type/player';
import { base64ParseImage } from './imageHelperFunctions';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import { saveAs } from 'file-saver';
import IconButton from '@/components/common/IconButton';
import Icon from '@/components/common/Icon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type Props = PropsWithChildren & {
  currentImageIndex: number;
  imageList: any;
  setCurrentImageIndex: any;
  onClose: any;
  open: boolean;
};

const CoolLightbox: React.FC<Props> = ({
  currentImageIndex,
  setCurrentImageIndex,
  imageList,
  open,
  onClose = () => null
}) => {
  return (
    <Modal open={open} onClose={onClose} noBackground={true}>
      <div className="flex justify-between w-full absolute left-0 top-0 p-5 z-50">
        <p className="text-white">{imageList[currentImageIndex].fileName}</p>
        <div className="flex gap-3">
          {localStorage.getItem('isAdmin') === 'true' && (
            <Button
              onClick={() => {
                saveAs(
                  base64ParseImage(
                    imageList[currentImageIndex].image,
                    imageList[currentImageIndex].fileType
                  ),
                  `image10Bet.${imageList[currentImageIndex].fileType}`
                );
              }}
              variant="bg-beautiful">
              Download
            </Button>
          )}
          <IconButton
            onClick={() => onClose()}
            className="bg-black h-[50px] w-[50px] text-white flex justify-center">
            <Icon name="close" />
          </IconButton>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <img
          className="rounded-2xl w-auto max-h-[80vh] max-w-[80vw] overflow-auto"
          src={base64ParseImage(
            imageList[currentImageIndex].image,
            imageList[currentImageIndex].fileType
          )}
        />
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center px-3">
        <IconButton
          onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
          disabled={currentImageIndex === 0}
          className="bg-black h-[50px] w-[50px] text-white flex justify-center">
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
          disabled={currentImageIndex === imageList.length - 1}
          className="bg-black h-[50px] w-[50px] text-white flex justify-center">
          <ChevronRightIcon />
        </IconButton>
      </div>
    </Modal>
  );
};

export default CoolLightbox;

import React, { useEffect, useMemo } from 'react';
import DropdownInformation from '@/components/widgets/sections/EmployeeInformation/DropdownInformation';
import EmployeeDetails from '@/components/widgets/sections/EmployeeInformation/EmployeeDetails';
import MuiSwitch from '@/components/common/Switch';
import { EmployeeDetails as EmployeeDetailsType } from '@/type/employee';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getEmployeeFilterData,
  employeeFilterDataSelector
} from '@/store/employee';
import EmployeePermissions from './EmployeePermissions';
import { UpdatePermission } from '@/type/employee';

type Props = {
  formData: EmployeeDetailsType;
  setFormData: any;
  handleChangeValue: any;
  initialEmployeeName: string;
  handleSubmit: any;
  isEditScreen: boolean;
  employeePermissions: UpdatePermission[];
  setEmployeePermissions: React.Dispatch<React.SetStateAction<UpdatePermission[]>>;
};

const EmployeeInformation: React.FC<Props> = ({
  formData,
  initialEmployeeName,
  handleChangeValue,
  setFormData,
  handleSubmit,
  isEditScreen,
  employeePermissions,
  setEmployeePermissions
}) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const filterData = useAppSelector(employeeFilterDataSelector);

  useEffect(() => {
    const fetchFilterData = async () => {
      await dispatch(getEmployeeFilterData());
    };

    fetchFilterData();
  }, []);

  const employeeRoleOptions = useMemo(() => {
    if (filterData?.titles) {
      const options = filterData.titles.map((item) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const contractTypeOptions = useMemo(() => {
    if (filterData?.contractTypes) {
      const options = filterData.contractTypes.map((item: any) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const employeeTitleOptions = useMemo(() => {
    if (filterData?.titles) {
      const options = filterData.titles.map((item) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const countryOptions = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.countries) {
      const options = filterData.countries.map((item) => ({
        label: item.countryName,
        value: item.countryName
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const departmentOptions = useMemo(() => {
    if (filterData?.departments) {
      // Since we are not expecting an initial All ____ from the backend
      const fixedFilterData = [...filterData.departments];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  return (
    <div>
      <div className="grid gap-4 grid-cols-2 row-span-1 mb-10">
        <div className="col-span-1">
          <h2 className="text-title font-medium mb-4">Personal Information</h2>
          <div className="shadow-paper bg-white p-4 py-5 rounded-lg">
            <EmployeeDetails
              data={formData}
              setData={setFormData}
              id={params.id}
              countryOptions={countryOptions}
              isEditScreen={isEditScreen}
            />
          </div>
        </div>

        <div className="grid grid-rows-3 col-span-1 gap-4">
          <div className="row-span-2">
            <h2 className="text-title font-medium mb-4">10bet Information</h2>
            <div className="shadow-paper p-4 bg-white rounded-lg">
              <DropdownInformation
                id={params.id}
                data={formData}
                setData={setFormData}
                name={formData.firstName + ' ' + formData.lastName}
                initialEmployeeName={initialEmployeeName}
                status={formData?.status}
                title="Test User"
                onChangeValue={handleChangeValue('employeeName')}
                fromEmployee={false}
                departmentOptions={departmentOptions}
                employeeTitleOptions={employeeTitleOptions}
                employeeContractTypeOptions={contractTypeOptions}
                employeeRoleOptions={employeeRoleOptions}
                isEditScreen={isEditScreen}
              />
            </div>
          </div>
          <div className="shadow-paper bg-white px-4 rounded-lg flex">
            <div
              className={
                'flex content-center self-center justify-between w-full'
              }>
              <div>
                <p className="font-semibold">Change user’s status</p>
                <p className="text-sm text-gray-300">
                  {"Activate / deactivate this employee's profile"}
                </p>
              </div>
              <div>
                <MuiSwitch
                  isDisabled={!isEditScreen}
                  className={`${
                    isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                  }`}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      status:
                        formData.status === 'Active' ? 'Inactive' : 'Active'
                    });
                  }}
                  isChecked={formData.status === 'Active' ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid gap-4 grid-cols-2 row-span-1 mb-10">
        <div className="col-span-1">
          <h2 className="text-title font-medium mb-4">Permissions</h2>
          <EmployeePermissions 
            isEditScreen={isEditScreen} 
            employeePermissions={employeePermissions}
            setEmployeePermissions={setEmployeePermissions}
            />

        </div>


      </div>
    </div>
  );
};

export default EmployeeInformation;

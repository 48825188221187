import React, { useState } from 'react';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import { base64ParseImage } from './imageHelperFunctions';
import { saveAs } from 'file-saver';
import './styles.css';

type Props = {
  nonParsedImage: any;
  fileType: string;
  fileName: string;
  index: number;
  setIsGalleryOpen: any;
  setCurrentImageIndex: any;
};

const SingleImageComponent: React.FC<Props> = ({
  nonParsedImage,
  fileType,
  fileName,
  index,
  setIsGalleryOpen,
  setCurrentImageIndex
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageBase64 = fileType && base64ParseImage(nonParsedImage, fileType);

  return (
    <div
      className="container"
      onContextMenu={(e: any) => {
        e.preventDefault();
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}>
      <img className="image" src={imageBase64} />

      {isHovered && (
        <div className="middle">
          <h1 className="text truncate break-words px-4 pt-4 pb-2 text-xs">
            {fileName}
          </h1>
          <div className="flex self-center">
            <IconButton
              small={true}
              onClick={() => {
                setCurrentImageIndex(index);
                setIsGalleryOpen(true);
              }}>
              <Icon name="eye" />
            </IconButton>
            {/* {localStorage.getItem('isAdmin') === 'true' && ( */}
              <IconButton
                small={true}
                onClick={() => {
                  saveAs(imageBase64, `image10Bet.${fileType}`);
                }}>
                <Icon name="download" />
              </IconButton>
            {/* )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleImageComponent;

import React, { PropsWithChildren } from 'react';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import { saveAs } from 'file-saver';
import IconButton from '@/components/common/IconButton';
import Icon from '@/components/common/Icon';


type Props = PropsWithChildren & {
  imageType: string,
  imageUrl: string,
  onClose: any;
  open: boolean;
};

const TableImageViewerModal: React.FC<Props> = ({
  imageType,
  imageUrl,
  open,
  onClose = () => null
}) => {
  return (
    <Modal open={open} onClose={onClose} noBackground={true}>
      <div className="flex justify-end w-full absolute left-0 top-0 p-5 z-50">
        <div className="flex gap-3">
          {/* {localStorage.getItem('isAdmin') === 'true' && ( */}
            <Button
              onClick={() => {
                console.log(imageUrl)
                saveAs(imageUrl, `image10Bet.${imageType}`)
            }}
              variant="bg-beautiful">
              Download
            </Button>
          {/* )} */}
          <IconButton
            onClick={() => onClose()}
            className="bg-black h-[50px] w-[50px] text-white flex justify-center">
            <Icon name="close" />
          </IconButton>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <img
          className="rounded-2xl w-auto max-h-[80vh] max-w-[80vw]"
          src={imageUrl}
        />
      </div>
    </Modal>
  );
};

export default TableImageViewerModal;

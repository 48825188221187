import React, { useState, useEffect } from 'react';
import { PlayerDocument } from '@/type/player';

type DropdownProps = {
  options: string[];
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  isEdit?: boolean;
  rowData?: PlayerDocument;
};


const Dropdown: React.FC<DropdownProps> = ({ 
  options, 
  selectedOption, 
  setSelectedOption,
  isEdit,
  rowData
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [hasDocumentTypeBeenSet, setHasDocumentTypeBeenSet] = useState(false);

  useEffect(() => {
      if (isEdit && rowData && !hasDocumentTypeBeenSet) {
          setSelectedOption(rowData.documentType);
      }
  }, [isEdit, rowData, hasDocumentTypeBeenSet]);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };


  const handleOptionClick = (option: string, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setSelectedOption(option);
    setIsDropdownOpen(false);
    if (isEdit && rowData) {
      setHasDocumentTypeBeenSet(true);
    }
  };



  return (
    <div 
      className="cursor-pointer w-[253px] h-12 px-6 py-1 bg-zinc-100 rounded-lg justify-start items-center gap-4 inline-flex relative"
      onClick={toggleDropdown}
    >
      <div className="grow shrink basis-0 rounded-2xl flex-col justify-center items-start gap-0.5 inline-flex w-[165px]">
        <div className="self-stretch h-[14px] text-neutral-500 text-xs font-medium font-['Inter'] leading-none tracking-tight">Document type</div>
        <div className="pb-3 self-stretch h-[18px] text-stone-800 text-base font-normal font-['Inter'] leading-normal tracking-wide truncate ...">{selectedOption}</div>
      </div>
      <div className="w-6 h-6 relative " >
        <svg style={{ transform: isDropdownOpen ? 'rotate(180deg)' : 'none' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Close">
            <path id="Forward" d="M18.9263 10L11.9632 16.9632L5 10" stroke="#716B70" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
        </svg>
      </div>

      {isDropdownOpen && (
        <div className="absolute mt-12 w-[230px] bg-zinc-100 rounded-lg shadow-md z-10 max-h-[200px] overflow-y-auto">
          {options.map((option, index) => (
            <div key={index} className="px-6 py-2 hover:bg-zinc-200 cursor-pointer" 
              onClick={(event) => handleOptionClick(option, event)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

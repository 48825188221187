import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginRequest, LoginResponse } from '@/type/api';
import * as authApi from '@/service/auth.service';
import { AxiosError } from 'axios';
import { isUnAuthorized } from '@/utils/api';

// Async actions
export const login = createAsyncThunk(
  'auth/login',
  async (
    data: LoginRequest,
    { rejectWithValue }
  ): Promise<LoginResponse | any> => {
    return authApi
      .login(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(
          isUnAuthorized(error) ? 'You made a mistake.' : error.message
        );
      });
  }
);

// Actions
export const setSigned = (payload: boolean) => {
  return {
    type: 'auth/setSigned',
    payload
  };
};

export const logout = () => {
  return {
    type: 'auth/logout'
  };
};

import React, { useEffect } from 'react';
import { Navigate, Outlet, OutletProps } from 'react-router-dom';
import './style.css';
import { useAppSelector, useAppDispatch } from '@/store';
import { isSignedInSelector } from '@/store/auth';
import { setSigned } from '@/store/auth';

const AuthLayout: React.FC<OutletProps> = (props) => {
  let isSigned = useAppSelector(isSignedInSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('dd_isSigned') === 'true') {
      isSigned = true;
      dispatch(setSigned(true));
    }
  }, [])

  return isSigned ? (
    <Navigate to="/players"></Navigate>
  ) : (
    <div className={`auth-layout`}>
      <div className="mb-[53px] flex items-center ml-10">
        <img src="/logo-mark-open-sidebar.svg" alt="logo" />
      </div>
      <Outlet {...props}></Outlet>
    </div>
  );
};

export default AuthLayout;

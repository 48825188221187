import React, { useMemo, useState } from 'react';
import ToggleInput from '@/components/common/ToggleInput';
import { PlayerInformation } from '@/type/player';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';

type Props = {
  data: PlayerInformation;
  id: string | undefined;
};

const editableContactFields: Array<{
  field: keyof PlayerInformation;
  label: string;
}> = [
  {
    field: 'email',
    label: 'Email address'
  },
  {
    field: 'dateOfBirth',
    label: 'Date of Birth'
  },
  {
    field: 'cellularPhone',
    label: 'Phone number'
  },
  {
    field: 'siteName',
    label: 'Site Name'
  }
];

const editableAddressFields: Array<{
  field: keyof PlayerInformation;
  label: string;
}> = [
  {
    field: 'countryName',
    label: 'Country'
  },
  {
    field: 'address',
    label: 'Address'
  },
  {
    field: 'city',
    label: 'City'
  },
  {
    field: 'zipCode',
    label: 'Zip Code'
  }
];

const PersonalDetails: React.FC<Props> = ({ data, id }) => {
  const [editable, setEditable] = useState(false);

  const fixedFields = useMemo(
    () => [
      {
        value: id || '',
        label: 'Playtech user ID'
      },
      {
        value: data?.customerID,
        label: 'Customer ID'
      },
      {
        value: data?.userName,
        label: 'Username'
      },
      {
        value: data?.affiliateID,
        label: 'Affiliate ID'
      }
    ],
    [data]
  );

  return (
    <>
      <div className="flex justify-around gap-x-4">
        <div className="w-1/3">
          {editableAddressFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                editable={editable}
                label={item.label}
                value={data[item.field]}
                country={
                  item.field === 'countryName' ? data?.countryName : undefined
                }
              />
            </div>
          ))}
        </div>
        <div className="w-1/3">
          {editableContactFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                editable={editable}
                label={item.label}
                value={data[item.field]}
              />
            </div>
          ))}
        </div>
        <div className="w-1/3">
          {fixedFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                editable={editable}
                className={index === 1 ? 'col-span-2' : ''}
                label={item.label}
                value={item.value}
                playerId={item.label === 'Player ID' ? item.value : undefined}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;

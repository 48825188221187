import React from 'react';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '@/components/widgets/tools/DateRangePicker';
import CheckIcon from '@mui/icons-material/Check';

type HeaderProps = {
  date?: any;
  setDate?: any;
  pageName: string;
  origin?: string;
  setFromCancel?: any;
  setCancelOrConfirmMessage?: any;
  isStateDirty?: boolean;
  handleCancel?: any;
  setIsConfirmOrCancelModalOpen?: any;
  setIsEditScreen?: any;
  isEditConfirmButtonDisabled?: boolean;
  isEditScreen?: boolean;
  triggerExportEvent?: any;
};

const backButtonPages = [
  'addNewEmployee',
  'editEmployee',
  'addNewEmployee',
  'userDetails',
  'employeeDetails'
];

const HeaderSection: React.FC<HeaderProps> = ({
  date,
  setDate,
  triggerExportEvent,
  pageName,
  setFromCancel,
  setCancelOrConfirmMessage,
  isStateDirty,
  handleCancel,
  setIsConfirmOrCancelModalOpen,
  setIsEditScreen,
  isEditConfirmButtonDisabled,
  isEditScreen,
  origin
}) => {
  const navigate = useNavigate();
  const goToAddNew = () => {
    navigate('/employees/add');
  };

  const handleBack = (origin: string) => {
    if (origin === 'closeButton') {
      if (isStateDirty) {
        setFromCancel(true);
        setCancelOrConfirmMessage(
          'Are you sure you want to discard these changes? ⚠️'
        );
        setIsConfirmOrCancelModalOpen(true);
      } else {
        handleCancel();
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex items-center p-4 mb-6 flex-col md:flex-row bg-white h-[80px]">
      {origin && backButtonPages.includes(origin) && (
        <button
          onClick={() => handleBack('backButton')}
          className="p-3 mr-6 self-center rounded-lg bg-black text-white">
          <Icon name="back" />
        </button>
      )}
      <h1 className="text-heading font-bold ml-2 text-[#A0A3BD]">{pageName}</h1>
      <div className="flex flex-1 flex-wrap justify-end gap-4 lg:gap-0">
        <div className="bg-[#EFF0F6] py-3 rounded-full">
          {date ? (
            <DateRangePicker date={date} setDate={setDate} />
          ) : (
            <div></div>
          )}
        </div>

        {origin === 'employees' ? (
          <Button
            className="sm:w-auto px-10 ml-1 my-2.5"
            variant="secondary"
            onClick={goToAddNew}
            disabled={localStorage.getItem('isAdmin') != 'true'}>
            <Icon className="mr-3.5 text-white" name="add" />
            Add new employee
          </Button>
        ) : origin === 'employeeDetails' ? (
          <div className="my-[0.17rem]">
            {isEditScreen ? (
              <div className="flex self-center items-center justify-center">
                <button
                  onClick={() => handleBack('closeButton')}
                  className="p-[18px] mr-6 self-center rounded-lg bg-black text-white">
                  <Icon name="close" />
                </button>
                <Button
                  disabled={isEditConfirmButtonDisabled}
                  variant="bg-beautiful"
                  className=" font-bold"
                  onClick={() => {
                    setFromCancel(false);
                    setCancelOrConfirmMessage(
                      'Are you sure you want to confirm these changes?'
                    );
                    setIsConfirmOrCancelModalOpen(true);
                  }}>
                  <CheckIcon className="mr-2" />
                  Confirm edition
                </Button>
              </div>
            ) : (
              <button
                onClick={() => setIsEditScreen(true)}
                className="p-[15px] mr-6 self-center rounded-lg bg-black text-white">
                <Icon name="pencil" />
              </button>
            )}
          </div>
        ) : (
          <div className="p-[2.2rem]"></div>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;

export const PLAYER_STATUS_COLOR = {
  Active: '#CBFFAE',
  Inactive: '#F7F7F7',
  'Self Excluded': '#AE2B83',
  'On Time Out': '#FFE6B0',
  Frozen: '#50C8FC'
};

export const VERIFIED_STATUS_COLOR = {
  'N/A': '#F7F7F7',
  Active: '#CBFFAE',
  Inactive: '#F7F7F7',
  Valid: '#CBFFAE',
  Invalid: '#F7F7F7',
  'Fully Verified': '#CBFFAE',
  'AML Soft Closure': '#F7F7F7',
  'External Verification Failed': '#FFABE8',
  'Externally Age Veriifed': '#CBFFAE',
  'Not Verified': '#FFABE8',
  'Customer Verified': '#CBFFAE'
};

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { setSigned } from '@/store/auth';
import * as authApi from '@/service/auth.service';
import ResetPasswordModal from '@/components/widgets/modal/ResetPasswordModal';

type Props = {
  setIsEmailLinkExpired: any;
};

const SessionToken: React.FC<Props> = ({ setIsEmailLinkExpired }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const sessionToken = location.pathname.split('/')[2];
  const [isShowResetPasswordModal, setIsShowResetPasswordModal] =
    useState(false);

  const handleCloseResetPasswordModal = () => {
    localStorage.setItem('dd_first_login', 'true');
    setIsShowResetPasswordModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await authApi.accessToken({
          sessionToken: sessionToken
        });
        const {
          accessToken,
          accessTokenExpiresAt,
          profile: {
            employeeId,
            firstName,
            lastName,
            email,
            hasLoggedIn,
            isAdmin
          }
        } = response || { profile: {} };
        accessToken &&
          accessTokenExpiresAt &&
          localStorage.setItem(
            'dd_token',
            JSON.stringify({
              accessToken: accessToken,
              accessTokenExpiresAt: accessTokenExpiresAt
            })
          );

        localStorage.setItem('isAdmin', isAdmin);
        employeeId && firstName && lastName && email;
        localStorage.setItem(
          'dd_account',
          JSON.stringify({
            id: employeeId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            hasLoggedIn: hasLoggedIn
          })
        );
        if (hasLoggedIn) {
          dispatch(setSigned(true));
          localStorage.setItem('dd_isSigned', 'true');
        } else {
          setIsShowResetPasswordModal(true);
        }
      } catch (e) {
        console.log(e);
        navigate('/');
        setIsEmailLinkExpired(true);
      }
    })();
  }, []);

  return (
    <>
      {isShowResetPasswordModal && (
        <ResetPasswordModal
          open={isShowResetPasswordModal}
          isFirstPasswordReset={true}
          onClose={handleCloseResetPasswordModal}
        />
      )}
    </>
  );
};

export default SessionToken;

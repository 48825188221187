import React, { PropsWithChildren } from 'react';
import './style.css';

const FilterContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-nowrap gap-5 pt-[1px] shrink">{children}</div>
  );
};

export default FilterContainer;

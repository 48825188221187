import moment from 'moment/moment';

/**
 * It returns dates between start date and end date
 * @Input 2022-10-10, 2022-10-13
 * @Output [2022-10-10, 2022-10-11, 2022-10-12, 2022-10-13]
 * */
export const getIntervalDates = (startDate: string, endDate: string) => {
  let start = startDate;
  const dates = [];

  while (moment(start).isSameOrBefore(moment(endDate))) {
    dates.push(start);
    const next = moment(start).add(1, 'd');
    start = next.format('YYYY-MM-DD');
  }

  return dates;
};

/**
 * It returns normalized date format
 * @Input 2022-10-10
 * @Output 10 Oct 2022
 * */
export const commonDateFormat = (date: string, showTime?: boolean) => {
  if (moment(date).isValid()) {
    return showTime
      ? moment(date).format('DD MMM YYYY hh:mm')
      : moment(date).format('DD MMM YYYY');
  }
  return date ?? '';
};

/**
 * It returns input type format date
 * @Input 10/10/2022
 * @Output 2022-10-10
 * */
export const convertInputType = (date: string) => {
  if (moment(date).isValid()) {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  return date ?? '';
};

/**
 * It returns input type format date
 * @Input 2022-10-10
 * @Output 10/10/2022
 * */
export const convertOutputType = (date: string) => {
  if (moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY');
  }
  return date ?? '';
};

/**
 * It returns validation of date input
 * @Input 10/10/2022
 * */
export const isValidDateInput = (date: string) => {
  return moment(date, 'DD/MM/YYYY').isValid();
};
export const isValidStartDateInput = (date: string, endDate: string) => {
  return moment(date, 'DD/MM/YYYY').isSameOrBefore(moment(endDate));
};
export const isValidEndDateInput = (date: string, startDate: string) => {
  return moment(date, 'DD/MM/YYYY').isSameOrAfter(moment(startDate));
};

import React, { PropsWithChildren, useState } from 'react';
import { PlayerDocumentList } from '@/type/player';
import Loader from '../../loaders/Loader';
import CoolLightbox from './lightbox';
import SingleImageComponent from './singleImageComponent';

type Props = PropsWithChildren & {
  documentList: PlayerDocumentList;
  documents: any;
  loading: boolean;
};

const DocumentSection: React.FC<Props> = ({
  documentList,
  documents,
  loading
}) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [imageList, setImageList] = useState(
    documentList?.length > 0 &&
      documentList
        .map((doc: any, i: number) => {
          if (
            doc &&
            ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'].includes(
              documentList[i].fileType
            )
          ) {
            return {
              image: documents[i],
              fileType: documentList[i].fileType,
              fileName: documentList[i].fileName
            };
          }
        })
        .filter((doc: any) => doc?.image)
  );

  if (loading) return <Loader />;

  return (
    <div className="shadow-paper bg-white p-4 sm:py-5 sm:px-8 col-span-3 grid-flow-auto rounded-3xl flex gap-3 overflow-auto">
      {imageList && imageList.length > 0
        ? imageList.map(
            (imageInfo: any, i: number) =>
              imageInfo && (
                <div key={i} className='min-w-[70px]'>
                  <SingleImageComponent
                    nonParsedImage={imageInfo?.image}
                    fileType={imageInfo.fileType}
                    fileName={imageInfo.fileName}
                    index={i}
                    setIsGalleryOpen={setIsGalleryOpen}
                    setCurrentImageIndex={setCurrentImageIndex}
                  />
                </div>
              )
          )
        : 'No images found'}
      {isGalleryOpen && (
        <CoolLightbox
          currentImageIndex={currentImageIndex}
          setCurrentImageIndex={setCurrentImageIndex}
          onClose={() => setIsGalleryOpen(false)}
          open={isGalleryOpen}
          imageList={imageList}
        />
      )}
    </div>
  );
};

export default DocumentSection;

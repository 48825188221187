import React from 'react';
import { ModalUnstyled, ModalUnstyledBackdropSlotProps } from '@mui/base';
import clsx from 'clsx';
import './style.css';

export type ModalProps = React.PropsWithChildren & {
  open: boolean;
  onClose: () => void;
  smallScreen?: boolean;
  origin?: string;
  noBackground?: boolean;
  padding?: string;
  maxWidth?:string;
  maxHeight?:string;
};

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
  noBackground,
  origin,
  smallScreen,
  padding,
  maxWidth,
  maxHeight
}) => {
  const BackdropUnStyled = React.forwardRef<
    HTMLDivElement,
    {
      open?: boolean;
      className: string;
    } & ModalUnstyledBackdropSlotProps
  >((props, ref) => {
    const { open, className, onClick } = props;

    return (
      <div
        className={clsx({ 'MuiBackdrop-open': open }, className)}
        ref={ref}
        onClick={origin !== 'resetPassword' ? onClick : undefined}
      />
    );
  });

  BackdropUnStyled.displayName = 'BackdropUnStyled';
  return (
    <ModalUnstyled
      className="modal"
      open={open}
      onClose={onClose}
      slots={{
        backdrop: BackdropUnStyled
      }}>
      <div
        className={`modal-container ${noBackground && '!bg-transparent'}
        ${maxHeight ? `!max-h-[${maxHeight}px]` : ''}
        ${ smallScreen ? '!max-w-[664px]' : (maxWidth ? `!max-w-[${maxWidth}px]` : '')
        }`}
        style={{ 
          padding: padding || '36px',
          // maxWidth: smallScreen ? undefined : (maxWidth || '664px')
        }} 
        >
        {children}
      </div>
    </ModalUnstyled>
  );
};

export default Modal;

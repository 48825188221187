import service from '@/service/http.service';
import {
  LoginRequest,
  LoginResponse,
  AccessTokenRequest,
  AccessTokenResponse,
  SendResetPasswordEmailRequest,
  ResetPasswordRequest
} from '@/type/api';
import { AxiosError } from 'axios';

export const healthCheck = () => {
  return service
    .get('/Auth/heart_beat')
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const login = (data: LoginRequest): Promise<any> => {
  return service
    .post<any>('/auth/auth-request', data)
    .then((response) => {
      if (!response) {
        throw new Error('You made a mistake.');
      }
      return response;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const accessToken = (
  data: AccessTokenRequest
): Promise<AccessTokenResponse> => {
  return service
    .post<AccessTokenResponse>(
      `/auth/access-token?sessionToken=${data.sessionToken}`
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const sendResetPasswordEmail = (
  data: SendResetPasswordEmailRequest
): Promise<any> => {
  return service
    .post('/employees/send-reset-password-email', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const resetPassword = (data: ResetPasswordRequest): Promise<any> => {
  return service
    .put('/employees/reset-password', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const resetFirstPassword = (
  data: ResetPasswordRequest
): Promise<any> => {
  return service
    .put('/employees/reset-first-password', data)
    .then((response) => response?.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetPlayerRequest,
  GetPlayerResponse
} from '@/type/api';
import * as playerApi from '@/service/player.service';
import { AxiosError } from 'axios';

export const getPlayers = createAsyncThunk(
  'player/getPlayers',
  async (
    data: GetPlayerRequest,
    { rejectWithValue }
  ): Promise<GetPlayerResponse | any> => {
    return playerApi
      .getPlayers(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        rejectWithValue(error.message);
      });
  }
);
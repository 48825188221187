import { IconType } from '@/components/common/Icon';

type Menu = {
  label: string;
  icon: IconType;
  path: string;
};

export const menu: Menu[] = [
  {
    label: 'Players',
    path: '/players',
    icon: 'players-menu-icon'
  },
  {
    label: 'Employees',
    path: '/employees',
    icon: 'employees-menu-icon'
  }
];

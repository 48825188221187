/**
 * It returns the optimized object
 * @Input { a: 'a', b: 'b', c: undefined, d: null }
 * @Output { a: 'a', b: 'b' }
 * */
export const optimizeObject = (data: Record<string, any>) => {
  const object = data;
  Object.entries(object).forEach(([field, value]) => {
    if ((value ?? null) === null) {
      delete object[field];
    }
  });

  return object;
};

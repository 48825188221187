import service from '@/service/http.service';
import {
  DeletePlayerRequest,
  GetPlayerRequest,
  GetPlayerResponse,
  GetSearchPlayerResponse,
  GetPlayerInfoRequest,
  GetPlayerDocumentRequest,
  GetPlayerDocumentResponse,
  PostUploadDocumentRequest,
  PostUploadDocumentResponse,
  PutUpdateDocumentData,
  GetDocumentAuditRequest,
  GetDocumentAuditResponse,
  PostCreateDocumentData,
  DeletePlayersResponse,
  DeleteDocumentFileRequest,
  DeleteDocumentDataRequest
} from '@/type/api';
import { PlayerInformation, PlayerDocumentList } from '@/type/player';
import { AxiosResponse } from 'axios';

export const getPlayers = async (
  params: GetPlayerRequest
): Promise<GetSearchPlayerResponse> => {
  return service
    .get('/players', {}, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => error.status);
};

export const getPlayerInfo = (
  params: GetPlayerInfoRequest
): Promise<PlayerInformation[]> => {
  const url = `/players/profiles?customerId=${params.customerId}&loginName=${params.loginName}`;

  return service
    .get(url)
    .then((response) => response?.data)
    .catch((error) => error.status);
};

export const getPlayerDocumentList = (
  params: GetPlayerInfoRequest
): Promise<PlayerDocumentList> => {
  const url = `/players/documents?customerId=${params.customerId}&loginName=${params.loginName}`;

  return service
    .get(url)
    .then((response) => response?.data)
    .catch((error) => error.status);
};

export const getDocumentAudit = (
  params: GetDocumentAuditRequest
): Promise<GetDocumentAuditResponse> => {
  const url = `/audit/?customerId=${params.customerId}&loginName=${params.loginName}&documentId=${params.documentId}`;

  return service
    .get(url)
    .then((response) => response?.data)
    .catch((error) => error.status);
};

export const getPlayerDocument = (
  params: GetPlayerDocumentRequest
): Promise<GetPlayerDocumentResponse> => {
  return service
    .get(`/players/documents/preview`, {}, params, {
      responseType: 'arraybuffer'
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error) {
        console.log('Received 401 status, retrying request...');
        return service
        .get(`/players/documents/preview`, {}, params, {
          responseType: 'arraybuffer'
        })
          .then(retryResponse => {
            return retryResponse.data;
          })
          .catch(retryError => {
            return undefined; // Return undefined if the retry fails
          });
      } else {
        console.error('Request failed:', error);
        return undefined; // Return undefined for other errors
      }
    });
};

export const postUploadDocumentFile = (
  params: GetPlayerInfoRequest,
  file: File
):  Promise<PostUploadDocumentResponse> => {
  const url = `/players/documents/upload?customerId=${params.customerId}&loginName=${params.loginName}`;

  const formData = new FormData();
  formData.append('file', file);

  return service
    .post<PostUploadDocumentResponse>(url, formData)
    .then((response) => response?.data)
    .catch((error) => {
      throw new Error(error.message);
    });
}

export const postCreateDocumentData = (
  params: GetPlayerInfoRequest,
  body: PostCreateDocumentData
): Promise<AxiosResponse<any>> => {

  const url = `/players/documents/create?customerId=${params.customerId}&loginName=${params.loginName}`
  return service
    .post(url, body)
    .then((response) => {
        return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const putUpdateDocumentData = (
  params: GetPlayerInfoRequest,
  body: PutUpdateDocumentData
): Promise<any> => {

  const url = `/players/documents/update?customerId=${params.customerId}&loginName=${params.loginName}`
  return service
    .put(url, body)
    .then((response) => {
        return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getKYCAttachmentById = (
  customerIdp: string,
  attachmentId: string
) => {
  return service
    .get(
      `/kycdata/${customerIdp}/details/attachments/${attachmentId}`,
      undefined,
      undefined,
      { responseType: 'arraybuffer' }
    )
    .then((response) => {
      const image = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      const resss = `data:${response.headers[
        'content-type'
      ].toLowerCase()};base64,${image}`;
      return resss;
    })
    .catch((error) => {
      throw error;
    });
};


// export const postDeletePlayers = (
//   body: PostDeletePlayerRequest
// ): Promise<PostDeletePlayerResponse> => {
//   return service
//     .post('/players/profiles/multiple', body)
//     .then((response) => {
//       if (response) {
//         return response;
//       } else {
//         throw new Error('Email domain not allowed');
//       }
//     })
//     .catch((error) => {
//       throw error;
//     });
// };


export const deletedDocumentData = (
  params: DeleteDocumentDataRequest
): Promise<AxiosResponse<any>> => {
  const url = `/players/documents/delete-info?customerId=${params.customerId}&loginName=${params.loginName}&documentId=${params.documentId}`;

  return service
    .remove(url)
    .then((response) => response)
    .catch((error) => error);
};
export const deleteDocumentFile = (
  params: DeleteDocumentFileRequest
): Promise<AxiosResponse<any>> => {
  const url = `/players/documents/delete?customerId=${params.customerId}&loginName=${params.loginName}&documentId=${params.fileName}`;

  return service
    .remove(url)
    .then((response) => response)
    .catch((error) => error);
};

export const deletePlayerProfile = (
  params: GetPlayerInfoRequest
): Promise<PlayerDocumentList> => {
  const url = `/players/profiles?customerId=${params.customerId}&loginName=${params.loginName}`;

  return service
    .remove(url)
    .then((response) => response)
    .catch((error) => error.status);
};

export const deleteMultiplePlayers = (
  body: DeletePlayerRequest[]
): Promise<DeletePlayersResponse> => {

  return service
    .remove('/players/profiles/multiple', body)
    .then((axiosResponse) => {
      // Assuming the axios response's data has the message property
      const response: DeletePlayersResponse = {
        message: axiosResponse.data.message
      };
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


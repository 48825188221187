import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DefaultLayout from '@/components/layouts/DefaultLayout';
import SessionToken from './containers/SessionToken';
import AuthLayout from '@/components/layouts/AuthLayout';
import Players from '@/containers/Players';
import PlayerDetail from '@/containers/Players/PlayerDetail';
import Employees from '@/containers/Employees';
import AddNewEmployee from './containers/Employees/AddNew';
import EmployeeDetail from './containers/Employees/EmployeeDetail';
import Login from '@/containers/Login';
import ResetPasswordToken from '@/containers/ResetPasswordToken';
import { LicenseInfo } from '@mui/x-license-pro';
import { PlayerProvider } from '@/contexts/playerContext'

LicenseInfo.setLicenseKey(
  'f3a22427964faf07e664792437faea60Tz02MjEyMixFPTE3MTA1NDMwNDQzMTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// Comment to trigger and test deployment 

function App() {
  const [isUserSignedOutModalOpen, setIsUserSignedOutModalOpen] =
    useState(false);
  const [isEmailLinkExpired, setIsEmailLinkExpired] = useState(false);
  return (
    <Routes>
      <Route key="DefaultLayout" path="/" element={<DefaultLayout />}>
        {/* Redirect from root to Players */}
        <Route key="" path="" element={<Navigate to="/players" />} />
        {/* Players routes */}
        <Route
          key="Players"
          path="players"
          element={
            <PlayerProvider>
              <Players
                setIsUserSignedOutModalOpen={setIsUserSignedOutModalOpen}
              />
            </PlayerProvider>
          }
        />
        <Route
          key="Player Detail"
          path="players/:id"
          element={
            <PlayerProvider>
              <PlayerDetail />
            </PlayerProvider>
          }
        />
        {/* Employee routes */}
        <Route key="Employees" path="employees" element={<Employees />} />
        <Route
          key="AddEmployee"
          path="/employees/add"
          element={<AddNewEmployee />}
        />
        <Route
          key="EditEmployee"
          path="/employee/:id"
          element={<EmployeeDetail />}
        />
      </Route>
      <Route key="AuthLayout" path="/" element={<AuthLayout />}>
        <Route
          path="login"
          element={
            <Login
              isUserSignedOutModalOpen={isUserSignedOutModalOpen}
              setIsUserSignedOutModalOpen={setIsUserSignedOutModalOpen}
              isEmailLinkExpired={isEmailLinkExpired}
              setIsEmailLinkExpired={setIsEmailLinkExpired}
            />
          }
        />
        <Route
          path="/start_session/:sessionToken"
          element={
            <SessionToken setIsEmailLinkExpired={setIsEmailLinkExpired} />
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <ResetPasswordToken setIsEmailLinkExpired={setIsEmailLinkExpired} />
          }
        />
      </Route>
    </Routes>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeInformation from '@/components/widgets/sections/EmployeeInformation';
import { getEmployeeInfo } from '@/service/employee.service';
import Loader from '@/components/widgets/loaders/Loader';
import { EmployeeDetails } from '@/type/employee';
import CancelOrConfirmModal from '@/components/widgets/modal/CancelOrConfirmModal';
import { Store } from 'react-notifications-component';
import HeaderSection from '@/components/widgets/sections/HeaderSection';
import { putEmployees } from '@/service/employee.service';
import './style.css';
import { isContinueDisabled } from '../helperFunctions';
import { UpdatePermission } from '@/type/employee';

const EmployeeDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [profileInfo, setProfileInfo] = useState<EmployeeDetails>(
    {} as EmployeeDetails
  ); // Holds the original profile information returned from the database. Doesn't change

  const [employeePermissions, setEmployeePermissions] = useState<UpdatePermission[]>([])
  const [formData, setFormData] = useState<EmployeeDetails>(); // Holds the edited state of the data.

  const [initialEmployeeName, setInitialEmployeeName] = useState('');
  const [isEditScreen, setIsEditScreen] = useState(false);
  const [isConfirmOrCancelModalOpen, setIsConfirmOrCancelModalOpen] =
    useState(false);
  const [cancelOrConfirmMessage, setCancelOrConfirmMessage] = useState('');
  const [fromCancel, setFromCancel] = useState(false);

  const handleChangeValue = (field: string) => (value: string) => {
    console.log(value);
  };

  useEffect(() => {
    if (params.id) {
      getEmployeeInfo(params.id)
        .then((employee) => {
          setProfileInfo(employee);
          // console.log('Profile info: ', profileInfo);
          setFormData(employee);
          setInitialEmployeeName(
            employee?.firstName + ' ' + employee?.lastName
            );
          setEmployeePermissions(employee?.permissions)
          // console.log('Employee permissions: ', employeePermissions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id]);


  // Add useEffect hooks to log the updated state
  useEffect(() => {
    console.log('Profile info updated: ', profileInfo);
  }, [profileInfo]);

  useEffect(() => {
    console.log('Employee permissions updated: ', employeePermissions);
  }, [employeePermissions]);

// Update profile info on permissions change
  useEffect(() => {
    setProfileInfo(prevState => ({ ...prevState, permissions: employeePermissions }));
  }, [employeePermissions]);


  // useEffect(() => {
  //   if (formData) {
  //     setFormData({ ...formData, formData.permissions: employeePermissions });
  //   }
  // }, [employeePermissions]);

  const handleBack = () => {
    navigate(-1);
  };

  const isStateDirty = () => {
    return JSON.stringify(profileInfo) !== JSON.stringify(formData);
  };

  const handleSubmit = async () => {
    //PUT EMPLOYEE NEW DATA

    const putEmployeeObject = {
      firstName: formData?.firstName || '',
      lastName: formData?.lastName || '',
      phoneNumber: formData?.phoneNumber || '',
      email: formData?.email || '',
      birthDate: formData?.birthDate || '',
      countryCode: formData?.countryCode || '',
      city: formData?.city || '',
      address: formData?.address || '',
      department: formData?.department || '',
      employeeRole: formData?.employeeRole || '',
      employeeTitle: formData?.employeeTitle || '',
      managerName: '',
      status: formData?.status || '',
      employeeContractType: formData?.employeeContractType || '',
      isRemote: formData?.isRemote == 'true' ? 'true' : 'false',
      employeeId: formData?.employeeId.toString() || '',
      permissions: employeePermissions
    };

    
    try {    
      const resp = await putEmployees(putEmployeeObject);
      
      if (resp && resp.status === 200) {
          Store.addNotification({
            title: 'Changes has been successfully saved! ✅',
            message: '',
            type: 'success',
            insert: 'top',
            container: 'bottom-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          if (formData?.employeeId) {
            const id = formData.employeeId.toString();
            const employee = await getEmployeeInfo(id);
            setProfileInfo(employee);
            setInitialEmployeeName(employee?.firstName + ' ' + employee?.lastName);
            setFormData({ ...employee });
          }
          setIsEditScreen(false);
        } else {
          throw new Error('Failed to update employee data');
        }
      } catch (error) {
        console.error(error);        
        Store.addNotification({
          title: 'There was a problem saving employee changes',
          message: '',
          type: 'danger',
          insert: 'top',
          container: 'bottom-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
  };

  const handleCancel = () => {
    //PUT EMPLOYEE NEW DATA
    setFormData({ ...profileInfo });
    setIsEditScreen(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <HeaderSection
        pageName="Employee profile"
        setFromCancel={setFromCancel}
        setCancelOrConfirmMessage={setCancelOrConfirmMessage}
        setIsConfirmOrCancelModalOpen={setIsConfirmOrCancelModalOpen}
        isStateDirty={isStateDirty()}
        handleCancel={handleCancel}
        setIsEditScreen={setIsEditScreen}
        isEditScreen={isEditScreen}
        isEditConfirmButtonDisabled={
          isContinueDisabled(
            formData?.firstName,
            formData?.lastName,
            formData?.phoneNumber,
            formData?.email,
            formData?.birthDate
          ) || !isStateDirty()
        }
        origin={'employeeDetails'}
      />

      <div className="mb-8 mx-6">
        {formData && (
          <EmployeeInformation
            formData={formData}
            employeePermissions={employeePermissions}
            setEmployeePermissions={setEmployeePermissions}
            handleChangeValue={handleChangeValue}
            setFormData={setFormData}
            isEditScreen={isEditScreen}
            initialEmployeeName={initialEmployeeName}
            handleSubmit={handleSubmit}
          />
        )}
      </div>

      <CancelOrConfirmModal
        message={cancelOrConfirmMessage}
        fromCancel={fromCancel}
        onConfirm={handleSubmit}
        onCancel={handleCancel}
        open={isConfirmOrCancelModalOpen}
        onClose={() => setIsConfirmOrCancelModalOpen(false)}
      />
    </>
  );
};

export default EmployeeDetail;

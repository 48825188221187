import React, { useEffect, useState } from 'react';
import PlayersTable from '@/components/widgets/tables/PlayersTable';
import HeaderSection from '@/components/widgets/sections/HeaderSection';
import { healthCheck } from '@/service/auth.service';
import { clearAndReload } from '@/service/http.service';
import { useAppDispatch } from '@/store';
import { setSigned } from '@/store/auth';

type Props = {
  setIsUserSignedOutModalOpen: any;
};

const Players: React.FC<Props> = ({ setIsUserSignedOutModalOpen }) => {
  const [gridReference, setGridReference] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      healthCheck();
    } catch (err) {
      console.log('Health check failed')
    }
  }, []);

  const shouldUserGetLoggedOut = () => {
    const tokens = localStorage.getItem('dd_token');
    if (tokens) {
      const { accessTokenExpiresAt } = JSON.parse(tokens || '');
      const dateTimeAccessTokenExpiresAt = new Date(accessTokenExpiresAt);
      
      // Add client side time check to compare to token expiry time from BE 
      const now = new Date();
      const currentTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

      return (
        localStorage.getItem('dd_isSigned') !== 'true' ||
        currentTime > dateTimeAccessTokenExpiresAt
      );
    } else {
      return true;
    }
  };

  const logUserOut = () => {
    dispatch(setSigned(false));
    clearAndReload();
    setTimeout(() => {
      setIsUserSignedOutModalOpen(true);
    }, 500);
  };

  useEffect(() => {
    if (shouldUserGetLoggedOut()) {
      logUserOut();
    }
    const interval = setInterval(() => {
      if (shouldUserGetLoggedOut()) {
        logUserOut();
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const triggerExportEvent = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gridReference?.current?.exportDataAsCsv) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gridReference.current.exportDataAsCsv();
    }
  };

  return (
    <>
      <HeaderSection
        pageName="Players"
        triggerExportEvent={triggerExportEvent}
      />
      <div className="mx-6">
        <PlayersTable
          setGridReference={setGridReference}
          gridReference={gridReference}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      </div>
    </>
  );
};

export default Players;

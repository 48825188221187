import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '@/components/layouts/DefaultLayout/Sidebar';
import { useResponsive } from '@/utils/hook/useResponsive';
import clsx from 'clsx';
import { Store } from 'react-notifications-component';
import { OutletProps } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { isSignedInSelector } from '@/store/auth';

const DefaultLayout: React.FC<OutletProps> = (props) => {
  const isSigned = useAppSelector(isSignedInSelector);

  const [collapsed, setCollapsed] = useState(false);

  const { isTablet } = useResponsive();

  useEffect(() => {
    setCollapsed(isTablet);
  }, [isTablet]);

  return !isSigned ? (
    <Navigate to="/login"></Navigate>
  ) : (
    <div className="flex min-h-[100vh] relative">
      <Sidebar
        isTablet={isTablet}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      />
      <div
        className={clsx(
          'flex-1 duration-300 overflow-hidden',
          !isTablet && !collapsed ? 'ml-[210px]' : 'ml-[70px]'
        )}>
        <Outlet {...props}></Outlet>
      </div>
    </div>
  );
};

export default DefaultLayout;

import React, { useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Table from '@/components/common/Table';
import { TableColumn } from '@/type/table';
import { VerificationStatus } from '@/components/common/Chips';
import IconButton from '@/components/common/IconButton';
import { base64ParseImage } from '../../../widgets/sections/DocumentSection/imageHelperFunctions'
import Button from '@/components/common/Button';
import UploadDocModal from '@/components/widgets/modal/UploadDocModal';
import HistoryEditsModal from '@/components/widgets/modal/HistoryEditsModal';
import PdfViewerModal from '@/components/widgets/modal/PdfViewerModal';
import dayjs from 'dayjs';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton
} from '@mui/x-data-grid-pro';
import { saveAs } from 'file-saver';
import { PlayerDocumentList } from '@/type/player';
import CreatedOnMenu from './CreatedOnMenu';
import { usePlayerContext } from '@/contexts/playerContext';
import { PlayerDocument } from '@/type/player';
import TableImageViewerModal from '../../modal/TableImageViewerModal';


type Props = {
  documentList: PlayerDocumentList;
  documents: any;
  userName: string;
  userCustomerId: string
  setIsDocumentModified:Dispatch<SetStateAction<boolean>>,
};

const DocumentTable: React.FC<Props> = ({ 
  documentList, 
  documents, 
  userName, 
  userCustomerId,
  setIsDocumentModified
}) => {

  console.log('Document list: ', documentList);
  console.log('Documents: ', documents);

  // Set row data to pass to Edit window
  const [rowData, setRowData] = useState<PlayerDocument>();

  // Set whether Modal is for upload or Edit of a document
  const [isEdit, setIsEdit] = useState(false);

  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageFileType,setimageFileType] = useState<string>("");
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [isUploadDocModalOpen, setIsUploadDocModalOpen] = useState(false);
  const [isHistoryEditsModalOpen, setIsHistoryEditsModalOpen] = useState(false);

  // Defined selected document identifiers
  // TODO: Pass to menu button
  const [documentId, setDocumentId] = useState("");
  const [customerId, setCustomerId] = useState("");

  const { customerName } = usePlayerContext();

  const CustomMenuIcon = () => <Icon name="more-vert" />;

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-end w-full">
        <div className="flex p-2">
          <Button variant="bg-beautiful" onClick={() => setIsUploadDocModalOpen(true)}>
            Upload document
          </Button>
          <GridToolbarColumnsButton
            disableRipple
            startIcon={<Icon name="columns" />}
            className="!ml-2.5 !bg-transparent !min-w-0"
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const documentColumns: TableColumn[] = [
    {
      field: 'fileName',
      label: 'File name',
      cellClassName: 'max-w-[80px]',
      tooltip: true,
      render: (row: any) => (
        <div className="flex items-center justify-center truncate break-words w-fit">
          
            <div
              className="text-sm text-left text-ctascale-darkMain truncate break-words w-fit hover:cursor-pointer"
              onClick={() => {
                const clickedRowName = row.value;
                const docNameIndex = documentList.findIndex(
                  (obj) => obj.fileName === clickedRowName
                );

                const currentDoc = documentList[docNameIndex]

                if ("pdf" ===  currentDoc.fileType) {
                  const blobUrl = URL.createObjectURL(
                    new Blob([documents[docNameIndex]], {
                      type: 'application/pdf'
                    })
                  );
                  setPdfBlobUrl(blobUrl);
                  setIsPdfViewerOpen(true);
                } else if (
                  ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'].includes(currentDoc.fileType)) {
                  console.log("console.log of the directElement",documents[docNameIndex])
                  // const image = base64ParseImage(documents[docNameIndex], currentDoc.fileType)
                  const image = URL.createObjectURL(
                    new Blob([documents[docNameIndex]], {
                      type: `application/${currentDoc.fileType.toLocaleLowerCase()}`
                    })
                  );
                  setImageUrl(image);
                  setimageFileType(currentDoc.fileType);
                  setIsImageModalOpen(true);
                } else {
                  const blob = URL.createObjectURL(
                    new Blob([documents[docNameIndex]], {
                      type: 'application/vnd.ms-excel;charset=utf-8'
                    })
                  );
                  saveAs(blob, currentDoc.fileName);
                }
              }}>
              {row.value}
            </div>
          
        </div>
      )
    },
    {
      field: 'uploadMethod',
      label: 'Upload method'
    },
    {
      field: 'uploadDate',
      label: 'Upload date',
      render: (row: any) => (
        <div>{dayjs(row.value).format('DD-MM-YYYY HH:MM')}</div>
      )
    },
    {
      field: 'fileType',
      label: 'File type'
    },
    {
      field: 'documentStatus',
      label: 'Status',
      render: (row: any) => VerificationStatus(row.value)
    },
    {
      field: 'note',
      label: 'Note'
    },
    {
      field: 'sourceFilePath',
      label: 'Source file path'
    },
    {
      field: 'customerId',
      label: 'Customer ID'
    },
    {
      field: 'documentType',
      label: 'Document type'
    },
    {
      field: 'createdOn',
      label: 'Created On',
      render: (row: any) => (
        <div className='inline-flex justify-start items-center w-60 relative pr-0 h-full'>
          <div className='flex-grow flex-shrink-0'>
            {dayjs(row.value).format('DD-MM-YYYY HH:MM')}
          </div>
          <div className='absolute right-0 z-50' style={{ top: '50%', transform: 'translateY(-50%)' }}>
            <CreatedOnMenu
              rowValues={row.row}
              setRowData={setRowData}
              selectedCustomerId={row.row.customerId ? row.row.customerId.toString() : ""}
              selectedDocumentId={row.row.id ? row.row.id.toString() : ""}
              setCustomerId={setCustomerId}
              setDocumentId={setDocumentId}
              setIsEdit={setIsEdit} 
              setIsUploadDocModalOpen={setIsUploadDocModalOpen}
              setIsHistoryEditsModalOpen={setIsHistoryEditsModalOpen} 
            />
          </div>
        </div>
      )
    }
  ];

  console.log('setIsDocumentModified in DocumentTable', typeof setIsDocumentModified);
  
  return (
    <div className="rounded-xl ">
      <Table
        origin="documents"
        CustomToolbar={CustomToolbar}
        CustomMenuIcon={CustomMenuIcon}
        pagination
        columns={documentColumns}
        data={documentList || []}
      />
      <UploadDocModal
        isEdit={isEdit}
        setIsDocumentModified={setIsDocumentModified}
        customerId={userCustomerId}
        rowData={rowData}
        setIsEdit={setIsEdit}
        userName={userName}
        open={isUploadDocModalOpen}
        onClose={() => {setIsUploadDocModalOpen(false); setIsEdit(false);}}
      />
      <TableImageViewerModal 
        imageType={imageFileType}
        imageUrl={imageUrl} 
        open={isImageModalOpen} 
        onClose={() => setIsImageModalOpen(false)}/>
      <PdfViewerModal 
        pdfUrl={pdfBlobUrl} 
        open={isPdfViewerOpen} 
        onClose={() => setIsPdfViewerOpen(false)}/>
      <HistoryEditsModal
        documentId={documentId}
        customerId={customerId}
        loginName={customerName || ""}
        open={isHistoryEditsModalOpen}
        onClose={() => setIsHistoryEditsModalOpen(false)}
      />
    </div>
  );
};

export default DocumentTable;

import React, { useState, useRef, useEffect } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import CloseIcon from '@/components/common/CloseButton';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import dayjs from 'dayjs';
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { VerificationStatus } from '@/components/common/Chips';
import { PlayerDocumentList } from '@/type/player';
import { TableColumn } from '@/type/table';
import { getDocumentAudit } from '@/service/player.service';
import { GetDocumentAuditResponse } from '@/type/api';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type Props = ModalProps & {
  documentId: string;
  customerId: string;
  loginName: string;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));


const HistoryEditsModal: React.FC<Props> = ({
  loginName,
  customerId,
  documentId,
  open,
  onClose
}) => {

  const searchRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [documentHistoryData, setDocumentHistoryData] = useState<GetDocumentAuditResponse>([]);
  // Set data filtered by search bar
  const [filteredData, setFilteredData] = useState<GetDocumentAuditResponse>([]);

  // Function to handle search bar
  const handleSearch = async () => {
    if (searchRef?.current?.value) {
        const searchValue = searchRef.current.value.toLowerCase();
        const newData = documentHistoryData.filter(item => {
          return (
            item.employeeName.toLowerCase().includes(searchValue) ||
            item.fileName.toLowerCase().includes(searchValue) ||
            item.documentType.toLowerCase().includes(searchValue) ||
            item.documentStatus.toLowerCase().includes(searchValue)
            // Add other fields if necessary
          );
        });
        setFilteredData(newData);
        console.log('Filtered data: ', newData);
    } else {
        setFilteredData(documentHistoryData); // Reset to original data if search is cleared
    }
  };


  useEffect(() => {

    if (documentId && customerId && loginName) {
      const payload = { 
        documentId: documentId, 
        loginName: loginName, 
        customerId: customerId 
      };

      setIsLoading(true);

      getDocumentAudit(payload)
        .then(data => {
          setDocumentHistoryData(data);
          setFilteredData(data);
          console.log("Doc audit data: ", data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, documentId, customerId, loginName]);

  const CustomMenuIcon = () => <Icon name="more-vert" />;

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="flex justify-between">
        <div className="flex justify-start items-center gap-3">
          <GridToolbarQuickFilter
            inputRef={searchRef}
            placeholder="Search by employee or document name, etc"
            className="mui-grid-searchbar"
            style={{ maxWidth: '250px' }}
          />
          <Button
            variant="bg-beautiful"
            className="w-[106px] !px-6 !py-3"
            onClick={handleSearch}
            >
            {' '}
            Search{' '}
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }

  
  const documentColumns: TableColumn[] = [
    {
      field: 'changeDateTime',
      label: 'Date modified',
      render: (row: any) => (
        <div>{dayjs(row.value).format('DD-MM-YYYY HH:MM')}</div>
      )
    },
    {
      field: 'employeeName',
      label: 'Employee name',
      render: (row: any) => (
        <div className='text-left text-ctascale-darkMain font-semibold'>
          {row.value}
        </div>
      )
    },
    {
      field: 'fileName',
      label: 'Document name',
    },
    {
      field: 'documentType',
      label: 'Document type'
    },
    {
      field: 'documentStatus',
      label: 'Status',
      render: (row: any) => VerificationStatus(row.value)
    },
    {
      field: 'note',
      label: 'Note',
      render: (row: any) => (
        <LightTooltip 
          title={row.value}
          // classes={{ tooltip: 'bg-white text-black shadow-sm text-xs' }}
        >
          <div>{row.value}</div>
        </LightTooltip>
        // <div className='text-left text-ctascale-darkMain font-semibold'>
        //   {row.value}
        // </div>
      )
    }
  ];

  return (
    <Modal 
      open={open} 
      onClose={onClose} 
      smallScreen={false} 
      padding='0px'
      maxWidth={'900'}
      maxHeight='500' 
       >
      <div className='px-4 py-2 overflow-y-auto'>
        <div className='flex justify-end mb-4'>
          <CloseIcon onClick={onClose} />
        </div>
        <div className='flex flex-col pb-12 pl-12 pr-12'>
          <h2 className="text-[32px] font-['Inter'] font-bold mb-5 text-left">
            History
          </h2>
          <Table
            origin="history"
            CustomToolbar={CustomToolbar}
            CustomMenuIcon={CustomMenuIcon}
            columns={documentColumns}
            pagination={false}
            data={filteredData || []}
          />
        </div>

      </div>
    </Modal>
  );
};

export default HistoryEditsModal;

import React from 'react';
import IconButton from '../IconButton';

type ColorKeys = 'black' | 'red';  // Add more keys as needed

interface CloseButtonProps {
    onClick: () => void;  // assuming onClick is a function that takes no arguments and returns nothing
    color?: ColorKeys;  // Optional color prop
  }

const inputColors: { [key: string]: string } = {
  'black': '#262338',
  'red': '#9E0038',
}

const DeleteButton: React.FC<CloseButtonProps> = ({ onClick, color = 'black' }) => {

  const strokeColor = inputColors[color] || '#262338';

  return (
    <IconButton onClick={onClick}>
        <svg className={color} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Line Lightmode/Delete">
            <path id="Vector" d="M18 6V5C18 3.34315 16.6569 2 15 2H11C9.34315 2 8 3.34315 8 5V6M2 6H24M16 12V17M10 12V17M4 6H22L21.3375 19.2497C21.2045 21.9107 19.0081 24 16.3438 24H9.65624C6.99187 24 4.79554 21.9107 4.66248 19.2497L4 6Z" stroke={strokeColor} strokeWidth="2.6" strokeLinecap="round"/>
          </g>
        </svg>

    </IconButton>
  );
};

export default DeleteButton;
